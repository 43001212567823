/**
 *               ii.                                         ;9ABH,          
 *              SA391,                                    .r9GG35&G          
 *              &#ii13Gh;                               i3X31i;:,rB1         
 *              iMs,:,i5895,                         .5G91:,:;:s1:8A         
 *               33::::,,;5G5,                     ,58Si,,:::,sHX;iH1        
 *                Sr.,:;rs13BBX35hh11511h5Shhh5S3GAXS:.,,::,,1AG3i,GG        
 *                .G51S511sr;;iiiishS8G89Shsrrsh59S;.,,,,,..5A85Si,h8        
 *               :SB9s:,............................,,,.,,,SASh53h,1G.       
 *            .r18S;..,,,,,,,,,,,,,,,,,,,,,,,,,,,,,....,,.1H315199,rX,       
 *          ;S89s,..,,,,,,,,,,,,,,,,,,,,,,,....,,.......,,,;r1ShS8,;Xi       
 *        i55s:.........,,,,,,,,,,,,,,,,.,,,......,.....,,....r9&5.:X1       
 *       59;.....,.     .,,,,,,,,,,,...        .............,..:1;.:&s       
 *      s8,..;53S5S3s.   .,,,,,,,.,..      i15S5h1:.........,,,..,,:99       
 *      93.:39s:rSGB@A;  ..,,,,.....    .SG3hhh9G&BGi..,,,,,,,,,,,,.,83      
 *      G5.G8  9#@@@@@X. .,,,,,,.....  iA9,.S&B###@@Mr...,,,,,,,,..,.;Xh     
 *      Gs.X8 S@@@@@@@B:..,,,,,,,,,,. rA1 ,A@@@@@@@@@H:........,,,,,,.iX:    
 *     ;9. ,8A#@@@@@@#5,.,,,,,,,,,... 9A. 8@@@@@@@@@@M;    ....,,,,,,,,S8    
 *     X3    iS8XAHH8s.,,,,,,,,,,...,..58hH@@@@@@@@@Hs       ...,,,,,,,:Gs   
 *    r8,        ,,,...,,,,,,,,,,.....  ,h8XABMMHX3r.          .,,,,,,,.rX:  
 *   :9, .    .:,..,:;;;::,.,,,,,..          .,,.               ..,,,,,,.59  
 *  .Si      ,:.i8HBMMMMMB&5,....                    .            .,,,,,.sMr 
 *  SS       :: h@@@@@@@@@@#; .                     ...  .         ..,,,,iM5 
 *  91  .    ;:.,1&@@@@@@MXs.                            .          .,,:,:&S 
 *  hS ....  .:;,,,i3MMS1;..,..... .  .     ...                     ..,:,.99 
 *  ,8; ..... .,:,..,8Ms:;,,,...                                     .,::.83 
 *   s&: ....  .sS553B@@HX3s;,.    .,;13h.                            .:::&1 
 *    SXr  .  ...;s3G99XA&X88Shss11155hi.                             ,;:h&, 
 *     iH8:  . ..   ,;iiii;,::,,,,,.                                 .;irHA  
 *      ,8X5;   .     .......                                       ,;iihS8Gi
 *         1831,                                                 .,;irrrrrs&@
 *           ;5A8r.                                            .:;iiiiirrss1H
 *             :X@H3s.......                                .,:;iii;iiiiirsrh
 *              r#h:;,...,,.. .,,:;;;;;:::,...              .:;;;;;;iiiirrss1
 *             ,M8 ..,....,.....,,::::::,,...         .     .,;;;iiiiiirss11h
 *             8B;.,,,,,,,.,.....          .           ..   .:;;;;iirrsss111h
 *            i@5,:::,,,,,,,,.... .                   . .:::;;;;;irrrss111111
 *            9Bi,:,,,,......                        ..r91;;;;;iirrsss1ss1111
 */
/*
 * author 1452726147@qq.com
 */
@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.ttf?t=1620468014361') format('truetype');
}
@font-face {
  font-family: "Graphik-Regular";
  src: url("../fonts/Graphik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Light";
  src: url("../fonts/Graphik-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik-Medium";
  src: url("../fonts/Graphik-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik-Semibold";
  src: url("../fonts/Graphik-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dizhi1:before {
  content: "\e617";
}

.icon-youjiantou:before {
  content: "\e603";
}

.icon-jishiben:before {
  content: "\e66b";
}

.icon-dianhua2:before {
  content: "\e671";
}

.icon-zhiyudingceng:before {
  content: "\e636";
}

.icon-email-fill:before {
  content: "\e856";
}

.icon-close:before {
  content: "\e61d";
}

.icon-sousuo1:before {
  content: "\e6fe";
}

.icon-baozhuang:before {
  content: "\e61c";
}

.icon-chanpinjieshao:before {
  content: "\e661";
}

.icon-baozhuang1:before {
  content: "\e6b7";
}

.icon-canshu:before {
  content: "\e609";
}

.icon-yingyong:before {
  content: "\e67e";
}

.icon-jiantouxia:before {
  content: "\e62d";
}

.icon-jieshao:before {
  content: "\e60a";
}

.icon-datuzhanshi:before {
  content: "\e641";
}

.icon-youshi:before {
  content: "\e60d";
}

.icon-chanpinzhanshi:before {
  content: "\e656";
}

.icon-moban:before {
  content: "\e623";
}

.icon-youxiang1:before {
  content: "\e606";
}

.icon-kehu:before {
  content: "\e66f";
}

.icon-shebeiguanli:before {
  content: "\e61f";
}

.icon-rongyu:before {
  content: "\e61b";
}

.icon-dianhua:before {
  content: "\e602";
}

.icon-zuojiantou:before {
  content: "\e6b0";
}

.icon-faguoshejijiang:before {
  content: "\e608";
}

.icon-dizhi:before {
  content: "\e628";
}

.icon-chuanzhen:before {
  content: "\e6a6";
}

.icon-lianxidianhua:before {
  content: "\eab7";
}

.icon-fenxiang:before {
  content: "\e604";
}

.icon-xiaochengxu_tubiao:before {
  content: "\e607";
}

.icon-lianxidianhua1:before {
  content: "\e622";
}

.icon-linkedin2:before {
  content: "\e646";
}

.icon-facebook1:before {
  content: "\e621";
}

.icon-yanjing:before {
  content: "\e63d";
}

.icon-pinterest1:before {
  content: "\e697";
}

.icon-whatsapp1:before {
  content: "\e739";
}

.icon-tuite:before {
  content: "\e6e5";
}

.icon-skype:before {
  content: "\e87d";
}

.icon-skype-fill:before {
  content: "\e882";
}

.icon-instagram-fill:before {
  content: "\e88f";
}

.icon-diqiu:before {
  content: "\e844";
}

.icon-you-tube:before {
  content: "\e650";
}

.icon-whatsapp-fill:before {
  content: "\e6bc";
}

.icon-guankan:before {
  content: "\e63e";
}

.icon-whatsapp:before {
  content: "\eb95";
}

.icon-fasong:before {
  content: "\e642";
}

.icon-zhanhui:before {
  content: "\e60f";
}

.icon-gongsi:before {
  content: "\e769";
}

.icon-gongsi1:before {
  content: "\e86d";
}

.icon-sousuo:before {
  content: "\e664";
}

.icon-gengduo3:before {
  content: "\e601";
}

.icon-gengduo3-copy:before {
  content: "\eb96";
}

.icon-rili:before {
  content: "\e7c9";
}

.icon--:before {
  content: "\e611";
}

.icon-zhuanli:before {
  content: "\e616";
}

.icon-youxiang:before {
  content: "\e65e";
}

.icon-icons_dashboard:before {
  content: "\e61e";
}

.icon-dianhua01:before {
  content: "\e68e";
}

.icon-gengduo:before {
  content: "\e605";
}

.icon-changjiantou-xia:before {
  content: "\e7ca";
}

.icon-tijiao:before {
  content: "\e610";
}

.icon-guowai:before {
  content: "\e6a7";
}

.icon-shuichangzhandimianji:before {
  content: "\e600";
}

.icon-jiantouzuo:before {
  content: "\e657";
}

.icon-kefu:before {
  content: "\e686";
}



/* add by chy */

@font-face {
  font-family: "iconfont-chy"; /* Project id 4274735 */
  src: url('../fonts/iconfont-chy.ttf') format('truetype');
}

.iconfont-chy {
  font-family: "iconfont-chy" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\ec77";
}

.icon-linkedin:before {
  content: "\e697";
}

.icon-facebook:before {
  content: "\e620";
}

.icon-twitter:before {
  content: "\e882";
}

.icon-shiming:before {
  content: "\e66a";
}

.icon-qiyeshiming:before {
  content: "\e691";
}

.icon-yuanjingyushiming:before {
  content: "\e611";
}

.icon-a-loyaltyprogram:before {
  content: "\e60f";
}
