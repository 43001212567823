/**
 *               ii.                                         ;9ABH,          
 *              SA391,                                    .r9GG35&G          
 *              &#ii13Gh;                               i3X31i;:,rB1         
 *              iMs,:,i5895,                         .5G91:,:;:s1:8A         
 *               33::::,,;5G5,                     ,58Si,,:::,sHX;iH1        
 *                Sr.,:;rs13BBX35hh11511h5Shhh5S3GAXS:.,,::,,1AG3i,GG        
 *                .G51S511sr;;iiiishS8G89Shsrrsh59S;.,,,,,..5A85Si,h8        
 *               :SB9s:,............................,,,.,,,SASh53h,1G.       
 *            .r18S;..,,,,,,,,,,,,,,,,,,,,,,,,,,,,,....,,.1H315199,rX,       
 *          ;S89s,..,,,,,,,,,,,,,,,,,,,,,,,....,,.......,,,;r1ShS8,;Xi       
 *        i55s:.........,,,,,,,,,,,,,,,,.,,,......,.....,,....r9&5.:X1       
 *       59;.....,.     .,,,,,,,,,,,...        .............,..:1;.:&s       
 *      s8,..;53S5S3s.   .,,,,,,,.,..      i15S5h1:.........,,,..,,:99       
 *      93.:39s:rSGB@A;  ..,,,,.....    .SG3hhh9G&BGi..,,,,,,,,,,,,.,83      
 *      G5.G8  9#@@@@@X. .,,,,,,.....  iA9,.S&B###@@Mr...,,,,,,,,..,.;Xh     
 *      Gs.X8 S@@@@@@@B:..,,,,,,,,,,. rA1 ,A@@@@@@@@@H:........,,,,,,.iX:    
 *     ;9. ,8A#@@@@@@#5,.,,,,,,,,,... 9A. 8@@@@@@@@@@M;    ....,,,,,,,,S8    
 *     X3    iS8XAHH8s.,,,,,,,,,,...,..58hH@@@@@@@@@Hs       ...,,,,,,,:Gs   
 *    r8,        ,,,...,,,,,,,,,,.....  ,h8XABMMHX3r.          .,,,,,,,.rX:  
 *   :9, .    .:,..,:;;;::,.,,,,,..          .,,.               ..,,,,,,.59  
 *  .Si      ,:.i8HBMMMMMB&5,....                    .            .,,,,,.sMr 
 *  SS       :: h@@@@@@@@@@#; .                     ...  .         ..,,,,iM5 
 *  91  .    ;:.,1&@@@@@@MXs.                            .          .,,:,:&S 
 *  hS ....  .:;,,,i3MMS1;..,..... .  .     ...                     ..,:,.99 
 *  ,8; ..... .,:,..,8Ms:;,,,...                                     .,::.83 
 *   s&: ....  .sS553B@@HX3s;,.    .,;13h.                            .:::&1 
 *    SXr  .  ...;s3G99XA&X88Shss11155hi.                             ,;:h&, 
 *     iH8:  . ..   ,;iiii;,::,,,,,.                                 .;irHA  
 *      ,8X5;   .     .......                                       ,;iihS8Gi
 *         1831,                                                 .,;irrrrrs&@
 *           ;5A8r.                                            .:;iiiiirrss1H
 *             :X@H3s.......                                .,:;iii;iiiiirsrh
 *              r#h:;,...,,.. .,,:;;;;;:::,...              .:;;;;;;iiiirrss1
 *             ,M8 ..,....,.....,,::::::,,...         .     .,;;;iiiiiirss11h
 *             8B;.,,,,,,,.,.....          .           ..   .:;;;;iirrsss111h
 *            i@5,:::,,,,,,,,.... .                   . .:::;;;;;irrrss111111
 *            9Bi,:,,,,......                        ..r91;;;;;iirrsss1ss1111
 */

@media screen and (min-width: 1910px) {
}

@media screen and (min-width: 1601px) and (max-width: 1910px) {
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .footer_from_box .item {
    margin-bottom: 10px;
  }
  .footer_t_r {
    justify-content: flex-end;
    -ms-justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  .footer_from_l {
    width: 40%;
  }
  .footer_from_r {
    width: 60%;
  }
  .footer_t_r_item {
    width: 40%;
  }
  .footer_t_r_item:nth-child(2) {
    display: none;
  }
  .footer_t_l {
    width: 30%;
  }
  .footer_t_r {
    width: 70%;
  }
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  .about_one_r .img {
    display: none;
  }
  .about_six_item {
    width: calc((100% - 5.21vw) / 2);
  }
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  /*~~~~~~~~~~~~ 公共css ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css ~~~~~~~~~~~*/
  .pro_deBoxTwo {
    display: none;
  }
  /* mobile  header */
  .mobile_header {
    display: block;
  }

  .mobile_menu_box {
    width: 350px;
    right: -350px;
    padding: 35px 5%;
  }
  /* header */
  .header_box {
    display: none;
  }
  /*~~~~~~~~~~~~ 公共css end* ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css end* ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css end* ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  .index_one,
  .index_five,
  .index_four,
  .index_three {
    align-items: center;
    -ms-align-items: center;
    -webkit-align-items: center;
  }
  .footer_from_box .item {
    margin-bottom: 10px;
  }
  .footer_t_r {
    justify-content: flex-end;
    -ms-justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  .footer_from_l {
    width: 40%;
  }
  .footer_t_r_item {
    width: 40%;
  }
  .footer_t_r_item:nth-child(1),
  .footer_t_r_item:nth-child(2) {
    display: none;
  }
  .footer_t_l {
    width: 45%;
  }
  .footer_t_r {
    width: 45%;
  }
  .footer_t_r_item {
    width: 100%;
  }
  .index_four_item {
    width: calc((100% - 90px) / 4);
    margin-right: 30px;
  }
  .index_four_list {
    width: 100%;
    margin: 0;
  }
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  .about_one_r .img {
    display: none;
  }
  .about_six_item {
    width: calc((100% - 5.21vw) / 2);
  }
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  .pro_list_dl dd {
    width: calc((100% - 30px) / 2);
    margin-right: 30px !important;
  }
  .pro_list_dl dd:nth-child(2n) {
    margin-right: 0 !important;
  }
  .pro_two_item .pic img {
    width: 35%;
  }
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  .soution_one_l {
    width: 30.2%;
  }
  .soution_one_r {
    width: calc((69.8% - 3.13vw));
  }
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  /*~~~~~~~~~~~~ 公共css * ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css * ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css * ~~~~~~~~~~~*/
  .pro_deBoxTwo {
    display: none;
  }
  /* mobile  header */
  .mobile_header {
    display: block;
  }

  .mobile_menu_box {
    width: 350px;
    right: -350px;
    padding: 35px 5%;
  }

  /* header */
  .header_box {
    display: none;
  }

  /* footer */
  .footer_from_box .item {
    margin-bottom: 10px;
  }
  .footer_t_r {
    justify-content: flex-end;
    -ms-justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  .footer_from_l {
    width: 40%;
  }
  .footer_t_r_item {
    width: 40%;
  }
  .footer_t_r_item:nth-child(1),
  .footer_t_r_item:nth-child(2) {
    display: none;
  }
  .footer_t_l {
    width: 45%;
  }
  .footer_t_r {
    width: 45%;
  }
  .footer_t_r_item {
    width: 100%;
  }
  .footer_b .wrapper {
    justify-content: center;
    -ms-justify-content: center;
    -webkit-justify-content: center;
  }
  .footer_b_text {
    margin-bottom: 10px;
  }
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  .index_banner_item {
    padding: 50px 0;
  }
  .index_banner_item .title {
    width: 100%;
  }
  /* .index_one */
  .index_five,
  .index_four,
  .index_three,
  .index_two,
  .index_one {
    padding: var(--space) 0;
  }
  .index_two_l {
    width: 100%;
  }
  .index_two_r {
    width: 100%;
  }
  /* .index_four_item */
  .index_four_list {
    margin: 0;
    width: 100%;
  }
  .index_four_item {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
    margin-bottom: 30px;
  }
  .index_four_item:nth-child(2n) {
    margin-right: 0;
  }
  .footer_from_l,
  .index_five_l {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer_from_r,
  .index_five_r {
    width: 100%;
  }

  .footer_from_box form {
    justify-content: flex-start;
    -ms-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    align-items: center;
    -ms-align-items: center;
    -webkit-align-items: center;
  }
  .footer_from_box .item {
    margin-bottom: 10px;
  }
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory    ~~~~~~~~~~~*/
  .patent_list_dl dd,
  .factory_list_dl dd {
    width: calc((100% - 20px) / 2);
    margin-right: 20px !important;
    margin-bottom: 20px !important;
  }
  .patent_list_dl dd:nth-child(2n),
  .factory_list_dl dd:nth-child(2n) {
    margin-right: 0 !important;
  }
  /*~~~~~~~~~~~~factory   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  .about_one_l {
    width: 90%;
    margin-bottom: 20px;
  }
  .about_one_r {
    width: 100%;
  }
  .about_one_r .img {
    display: none;
  }
  .about_six_item {
    width: calc((100% - 5.21vw) / 2);
  }
  .about_four_b_item .right {
    padding: 0;
    width: 50%;
  }
  .cooperation_one_b_item .item .item_dian img {
    width: 30%;
  }
  .about_six_item .pic img {
    width: 20%;
  }
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
  .pro_deOne1_L {
    width: 100%;
    margin-bottom: 20px;
  }
  .pro_deOne1_R {
    width: 100%;
  }
  .pro_deOne1_L_dots .item {
    height: 137px;
  }
  .pro_deOne1_L_dots {
    height: calc(550px - 55px);
  }
  .pro_del_three_r {
    width: 100%;
    margin-bottom: 20px;
  }
  .pro_del_three_l {
    width: 100%;
  }
  .pro_del_six_b {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  .pro_del_six_list {
    width: 100%;
  }
  .pro_del_six_b .pic {
    width: 40%;
    margin: 30px auto;
  }
  .pro_del_seven_r {
    width: 100%;
    justify-content: flex-start;
    -ms-justify-content: flex-start;
    -webkit-justify-content: flex-start;
  }
  .pro_del_seven_l {
    margin-bottom: 20px;
    width: 100%;
  }
  /*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  .pro_list_dl dd {
    width: calc((100% - 30px) / 2);
    margin-right: 30px !important;
  }
  .pro_list_dl dd:nth-child(2n) {
    margin-right: 0 !important;
  }
  .pro_two_item .pic img {
    width: 20%;
  }
  .pro_one_list .list {
    margin-top: -10px;
  }
  .pro_one_item {
    margin: 0 0 0 10px;
  }
  .pro_two_item {
    width: calc((100% - 40px) / 2);
    margin: 0 20px 20px 0;
  }
  .pro_two_item:nth-child(2n) {
    margin-right: 0;
  }
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
  .cover_one_item {
    width: 100%;
  }
  /*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
  .case_list_l {
    width: calc(100% - 20px);
    margin-bottom: 40px;
  }
  .case_list_r {
    width: 100%;
    padding: 0 !important;
  }
  /*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  .soution_one_l {
    width: 100%;
    margin-bottom: 20px;
  }
  .soution_one_r {
    margin: 0;
    width: 100%;
  }
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list    ~~~~~~~~~~~*/
  .solution_list_dl dd {
    width: calc((100% - 20px) / 2);
    margin-right: 20px !important;
  }
  .solution_list_dl dd:nth-child(2n) {
    margin-right: 0 !important;
  }
  /*~~~~~~~~~~~~soltion_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
  .service_one_l {
    width: 48%;
  }
  /* .service_four */
  .service_four_item {
    width: calc((100% - 30px) / 2);
    margin: 0 30px 30px 0 !important;
  }
  .service_four_item:nth-child(2n) {
    margin-right: 0 !important;
  }
  /*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
  .news_two_t_l,
  .news_list2_item .left {
    width: 100%;
    margin-bottom: 20px;
  }
  .news_two_t_r,
  .news_list2_item .right {
    width: 100%;
  }
  /*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us    ~~~~~~~~~~~*/
  .contact_one_l{
    width: 100%;
    margin-bottom: 20px;
  }
  .contact_one_r{
    width: 100%;
  }
  /*~~~~~~~~~~~~contact——us   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us   end ~~~~~~~~~~~*/
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  /*~~~~~~~~~~~~ 公共css * ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css * ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css * ~~~~~~~~~~~*/
  .public_module_title .subtitle {
    padding-bottom: 15px;
  }

  .public_module_title .text {
    line-height: 24px;
  }

  /*·········· 移动 ··········*/
  .mobile_header {
    display: block;
  }

  .pc_public_head {
    display: none;
  }

  /*·········· 移动 end ·········· */

  /*·········· header ··········*/
  .header_box {
    display: none;
  }

  /*··········header end ··········*/

  /*·········· 模块边距 ··········*/

  .w_1600 {
    width: 100% !important;
    padding: 0 15px;
  }
  .w_1400 {
    width: 100% !important;
    padding: 0 15px;
  }
  .pro_deBoxTwo {
    display: none;
  }
  /*~~~~~~~~~~~~ 公共css end* ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css end* ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~ 公共css end* ~~~~~~~~~~~*/

  /* footer */
  .footer_from_box .item {
    margin-bottom: 10px;
    width: 100%;
    margin-left: 0;
  }
  .footer_from_box .item.submit input,
  .footer_from_box .item.messgaes input,
  .footer_from_box .item input {
    width: 100%;
    padding-left: 15px;
  }
  .footer_t_r {
    justify-content: flex-end;
    -ms-justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
  .footer_from_l {
    width: 40%;
  }
  .footer_t_r_item {
    width: 40%;
  }
  .footer_t_r_item:nth-child(1),
  .footer_t_r_item:nth-child(2) {
    display: none;
  }
  .footer_t_l {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer_t_r {
    width: 100%;
  }
  .footer_t_r_item {
    width: 100%;
  }
  .footer_b .wrapper {
    justify-content: center;
    -ms-justify-content: center;
    -webkit-justify-content: center;
  }
  .footer_b_text {
    margin-bottom: 10px;
  }

  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index    ~~~~~~~~~~~*/
  .index_banner_item {
    padding: 50px 0;
  }
  .index_banner_item .title {
    width: 100%;
  }
  /* .index_one */
  .index_five,
  .index_four,
  .index_three,
  .index_two,
  .index_one {
    padding: var(--space) 0;
  }
  .index_two_l {
    width: 100%;
  }
  .index_two_r {
    width: 100%;
    margin-left: 15px;
  }
  .aboBoxFour2 .item {
    width: 100%;
    height: 150px;
  }
  .aboBoxFour2 .item.hover {
    width: 100%;
    height: 310px;
  }
  /* .index_four_item */
  .index_four_list {
    margin: 0;
    width: 100%;
  }
  .index_four_item {
    width: calc((100% - 10px) / 2);
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .index_four_item:nth-child(2n) {
    margin-right: 0;
  }
  .footer_from_l,
  .index_five_l {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer_from_r,
  .index_five_r {
    width: 100%;
  }

  .footer_from_box form {
    justify-content: flex-start;
    -ms-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    align-items: center;
    -ms-align-items: center;
    -webkit-align-items: center;
  }
  .footer_from_box .item {
    margin-bottom: 10px;
  }
  .index_five_item .pic {
    width: 100%;
    margin-bottom: 15px;
  }
  .index_five_item .con {
    width: 100%;
  }

  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~index   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory    ~~~~~~~~~~~*/
  .factory_list_table {
    justify-content: flex-start;
    -ms-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    padding: 10px 0;
  }
  .patent_list_dl dd,
  .factory_list_dl dd {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  /*~~~~~~~~~~~~factory   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~factory   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
  .about_one_l {
    width: 85%;
    margin-bottom: 20px;
  }
  .about_one_r {
    width: 100%;
  }
  .about_one_r .img {
    display: none;
  }
  .about_six_item {
    width: 100%;
    margin-right: 0;
  }
  .about_four_b_item .right {
    padding: 0;
    width: 50%;
  }

  .cooperation_one_b_item .item .item_dian img {
    width: 30%;
  }
  .about_six_item .pic img {
    width: 15%;
  }
  .suppThree1_L {
    width: 100%;
    padding: 30px 0;
  }
  .suppThree1_R {
    width: 100%;
  }
  .about_box5_scroll_item_l,
  .about_four_b_item .left {
    width: 100%;
    margin-bottom: 20px;
  }
  .about_box5_scroll_item_r,
  .about_four_b_item .right {
    width: 100%;
  }

  .about_five_item {
    width: 100%;
    margin-right: 0;
  }
  .about_box5_scroll_item_r .item {
    width: calc((100% - 10px) / 2);
  }
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
  .pro_deOne1_L {
    width: 100%;
    margin-bottom: 20px;
  }
  .pro_deOne1_R {
    width: 100%;
  }
  .pro_deOne1_L_dots .item {
    height: 80px;
  }
  .pro_deOne1_L_dots {
    height: calc(300px - 30px);
  }
  .pro_del_three_r {
    width: 100%;
    margin-bottom: 20px;
  }
  .pro_del_three_l {
    width: 100%;
  }
  .pro_del_six_b {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  .pro_del_six_list {
    width: 100%;
  }
  .pro_del_six_b .pic {
    width: 70%;
    margin: auto;
  }
  .pro_del_seven_r {
    width: 100%;
    justify-content: flex-start;
    -ms-justify-content: flex-start;
    -webkit-justify-content: flex-start;
  }
  .pro_del_seven_l {
    margin-bottom: 20px;
    width: 100%;
  }
  .pro_del_five_item {
    width: 100%;
  }
  .contact_form_b form .item {
    width: 100%;
    margin-right: 0;
  }
  /*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
  .pro_one_list {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  .pro_list_dl dd {
    width: 100%;
    margin-right: 0px !important;
  }
  .pro_list_dl dd:nth-child(2n) {
    margin-right: 0 !important;
  }
  .pro_two_item .pic img {
    width: 15%;
  }
  .pro_one_list .list {
    margin-top: 10px;
  }
  .pro_one_item {
    margin: 0;
  }
  .pro_two_item {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    text-align: center;
  }
  .pro_two_item:nth-child(2n) {
    margin-right: 0;
  }
  .pro_two_item .title {
    justify-content: center;
    -ms-justify-content: center;
    -webkit-justify-content: center;
  }
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
  .cover_one_item {
    width: 100%;
  }
  /*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
  .case_list_l {
    width: calc(100% - 20px);
    margin-bottom: 40px;
  }
  .case_list_r {
    width: 100%;
    padding: 0 !important;
  }
  .case_one_btn {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  /*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution    ~~~~~~~~~~~*/
  .soution_one_l {
    width: 100%;
    margin-bottom: 20px;
  }
  .soution_one_r {
    margin: 0;
    width: 100%;
  }
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~solution   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list    ~~~~~~~~~~~*/

  .solution_list_dl dd {
    width: 100%;
    margin-right: 0 !important;
  }

  .solution_list_table {
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    overflow-x: auto;
  }
  .solution_list_table .item {
    white-space: nowrap;
    overflow: visible;
  }

  /*~~~~~~~~~~~~soltion_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~soltion_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
  .service_one_b_swiper {
    width: 100%;
  }
  .service_one_b_item .title {
    white-space: wrap;
  }
  .service_one_l {
    width: 100%;
    margin-bottom: 20px;
  }
  .service_one_r {
    width: 100%;
  }
  /* .service_four */
  .service_four_item {
    width: calc((100% - 30px) / 2);
    margin: 0 30px 30px 0 !important;
  }
  .service_four_item:nth-child(2n) {
    margin-right: 0 !important;
  }
  /* .service_Two */
  .service_two_b {
    width: 100%;
  }
  .service_Two_swiper {
    width: 100%;
    padding: 0;
  }
  .service_Two_item {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  .service_Two_item .con {
    width: 100%;
  }
  .service_Two_item .pic {
    width: 100%;
    margin-bottom: 20px;
    transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
  }
  /*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
  .news_list_dl{
    padding: 0;
  }
  .news_two_t_l,
  .news_list2_item .left {
    width: 100%;
    margin-bottom: 20px;
  }
  .news_two_t_r,
  .news_list2_item .right {
    width: 100%;
  }
  /*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
   /*~~~~~~~~~~~~contact——us    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us    ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us    ~~~~~~~~~~~*/
  .contact_one_l{
    width: 100%;
    margin-bottom: 20px;
  }
  .contact_one_r{
    width: 100%;
  }
  .contact_form_box>.item{
    width: 100%;
  }
  /*~~~~~~~~~~~~contact——us   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us   end ~~~~~~~~~~~*/
  /*~~~~~~~~~~~~contact——us   end ~~~~~~~~~~~*/
}
