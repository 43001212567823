/**
 *               ii.                                         ;9ABH,          
 *              SA391,                                    .r9GG35&G          
 *              &#ii13Gh;                               i3X31i;:,rB1         
 *              iMs,:,i5895,                         .5G91:,:;:s1:8A         
 *               33::::,,;5G5,                     ,58Si,,:::,sHX;iH1        
 *                Sr.,:;rs13BBX35hh11511h5Shhh5S3GAXS:.,,::,,1AG3i,GG        
 *                .G51S511sr;;iiiishS8G89Shsrrsh59S;.,,,,,..5A85Si,h8        
 *               :SB9s:,............................,,,.,,,SASh53h,1G.       
 *            .r18S;..,,,,,,,,,,,,,,,,,,,,,,,,,,,,,....,,.1H315199,rX,       
 *          ;S89s,..,,,,,,,,,,,,,,,,,,,,,,,....,,.......,,,;r1ShS8,;Xi       
 *        i55s:.........,,,,,,,,,,,,,,,,.,,,......,.....,,....r9&5.:X1       
 *       59;.....,.     .,,,,,,,,,,,...        .............,..:1;.:&s       
 *      s8,..;53S5S3s.   .,,,,,,,.,..      i15S5h1:.........,,,..,,:99       
 *      93.:39s:rSGB@A;  ..,,,,.....    .SG3hhh9G&BGi..,,,,,,,,,,,,.,83      
 *      G5.G8  9#@@@@@X. .,,,,,,.....  iA9,.S&B###@@Mr...,,,,,,,,..,.;Xh     
 *      Gs.X8 S@@@@@@@B:..,,,,,,,,,,. rA1 ,A@@@@@@@@@H:........,,,,,,.iX:    
 *     ;9. ,8A#@@@@@@#5,.,,,,,,,,,... 9A. 8@@@@@@@@@@M;    ....,,,,,,,,S8    
 *     X3    iS8XAHH8s.,,,,,,,,,,...,..58hH@@@@@@@@@Hs       ...,,,,,,,:Gs   
 *    r8,        ,,,...,,,,,,,,,,.....  ,h8XABMMHX3r.          .,,,,,,,.rX:  
 *   :9, .    .:,..,:;;;::,.,,,,,..          .,,.               ..,,,,,,.59  
 *  .Si      ,:.i8HBMMMMMB&5,....                    .            .,,,,,.sMr 
 *  SS       :: h@@@@@@@@@@#; .                     ...  .         ..,,,,iM5 
 *  91  .    ;:.,1&@@@@@@MXs.                            .          .,,:,:&S 
 *  hS ....  .:;,,,i3MMS1;..,..... .  .     ...                     ..,:,.99 
 *  ,8; ..... .,:,..,8Ms:;,,,...                                     .,::.83 
 *   s&: ....  .sS553B@@HX3s;,.    .,;13h.                            .:::&1 
 *    SXr  .  ...;s3G99XA&X88Shss11155hi.                             ,;:h&, 
 *     iH8:  . ..   ,;iiii;,::,,,,,.                                 .;irHA  
 *      ,8X5;   .     .......                                       ,;iihS8Gi
 *         1831,                                                 .,;irrrrrs&@
 *           ;5A8r.                                            .:;iiiiirrss1H
 *             :X@H3s.......                                .,:;iii;iiiiirsrh
 *              r#h:;,...,,.. .,,:;;;;;:::,...              .:;;;;;;iiiirrss1
 *             ,M8 ..,....,.....,,::::::,,...         .     .,;;;iiiiiirss11h
 *             8B;.,,,,,,,.,.....          .           ..   .:;;;;iirrsss111h
 *            i@5,:::,,,,,,,,.... .                   . .:::;;;;;irrrss111111
 *            9Bi,:,,,,......                        ..r91;;;;;iirrsss1ss1111
 */
/*
 * author 1452726147@qq.com
 */

#baguetteBox-overlay {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d9d9d9;
}

::-webkit-scrollbar-thumb:active {
  background-color: #554f5e;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

video {
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Graphik-Regular", "Arial", "sans-serif";
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

li {
  list-style: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

a {
  text-decoration: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}

a:hover {
  text-decoration: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  border-width: 1px;
  font-size: 14px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: none;
}

/* animation  */
.wow {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

/* ~~~~~~~~~~~~~~~~pc 导航,css */
/* ~~~~~~~~~~~~~~~~pc 导航,css */
/* ~~~~~~~~~~~~~~~~pc 导航,css */
/* 色调 */
:root {
  --hue: #005a9c;
  --hue2: #0064b0;
  --space: clamp(3.75rem, 2.661rem + 4.84vw, 7.5rem);
  --fwr: "Graphik-Regular";
  --fwl: "Graphik-Light";
  --fwm: "Graphik-Medium";
  --fws: "Graphik-Semibold";
  --color23: #232323;
  --color56: #565656;
  --color89: #898989;
}

.colorm {
  color: var(--hue);
}

.color23 {
  color: var(--color23);
}

.color56 {
  color: var(--color56);
}

.color89 {
  color: var(--color89);
}
.bgm {
  background-color: var(--hue);
}
/* 字体 */
.fwr {
  font-family: var(--fwr);
}


.fwm {
  font-family: var(--fwm);
}


.fwl {
  font-family: var(--fwl);
}

.fws {
  font-family: var(--fws);
}

/* 字体大小 */

.fz72 {
  font-size: clamp(1.625rem, 0.554rem + 4.51vw, 4.5rem);
}

.fz70 {
  font-size: clamp(1.625rem, 0.6rem + 4.31vw, 4.375rem);
}

.fz68 {
  font-size: clamp(1.625rem, 0.647rem + 4.12vw, 4.25rem);
}

.fz66 {
  font-size: clamp(1.625rem, 0.694rem + 3.92vw, 4.125rem);
}

.fz64 {
  font-size: clamp(1.625rem, 0.74rem + 3.73vw, 4rem);
}

.fz62 {
  font-size: clamp(1.625rem, 0.787rem + 3.53vw, 3.875rem);
}

.fz60 {
  font-size: clamp(1.625rem, 0.833rem + 3.33vw, 3.75rem);
}

.fz58 {
  font-size: clamp(1.625rem, 0.88rem + 3.14vw, 3.625rem);
}

.fz56 {
  font-size: clamp(1.625rem, 0.926rem + 2.94vw, 3.5rem);
}

.fz54 {
  font-size: clamp(1.625rem, 0.973rem + 2.75vw, 3.375rem);
}

.fz52 {
  font-size: clamp(1.625rem, 1.02rem + 2.55vw, 3.25rem);
}

.fz50 {
  font-size: clamp(1.5rem, 0.895rem + 2.55vw, 3.125rem);
}

.fz48 {
  font-size: clamp(1.5rem, 0.941rem + 2.35vw, 3rem);
}

.fz46 {
  font-size: clamp(1.5rem, 0.988rem + 2.16vw, 2.875rem);
}

.fz44 {
  font-size: clamp(1.5rem, 0.988rem + 2.16vw, 2.875rem);
}

.fz42 {
  font-size: clamp(1.5rem, 1.081rem + 1.76vw, 2.625rem);
}

.fz40 {
  font-size: clamp(1.375rem, 0.956rem + 1.76vw, 2.5rem);
}

.fz38 {
  font-size: clamp(1.375rem, 1.002rem + 1.57vw, 2.375rem);
}

.fz36 {
  font-size: clamp(1.375rem, 1.049rem + 1.37vw, 2.25rem);
}

.fz34 {
  font-size: clamp(1.25rem, 0.924rem + 1.37vw, 2.125rem);
}

.fz32 {
  font-size: clamp(1.25rem, 0.971rem + 1.18vw, 2rem);
}

.fz30 {
  font-size: clamp(1.25rem, 1.017rem + 0.98vw, 1.875rem);
}

.fz28 {
  font-size: clamp(1.25rem, 1.064rem + 0.78vw, 1.75rem);
}

.fz26 {
  font-size: clamp(1.125rem, 0.939rem + 0.78vw, 1.625rem);
}

.fz24 {
  font-size: clamp(1.125rem, 0.985rem + 0.59vw, 1.5rem);
}

.fz22 {
  font-size: clamp(1rem, 0.86rem + 0.59vw, 1.375rem);
}

.fz20 {
  font-size: clamp(1rem, 0.907rem + 0.39vw, 1.25rem);
}

.fz18 {
  font-size: clamp(1rem, 0.953rem + 0.2vw, 1.125rem);
}

.fz16 {
  font-size: clamp(0.875rem, 0.828rem + 0.2vw, 1rem);
}
.fz14 {
  font-size: 14px;
  line-height: 24px !important;
}
.pt {
  padding-top: var(--space);
}

.pb {
  padding-bottom: var(--space);
}

.ptb {
  padding-top: var(--space);
  padding-bottom: var(--space);
}

/* ~~~~分页~~~~~~ */
/* ~~~~分页~~~~~~ */
/* ~~~~分页~~~~~~ */
/* ~~~~分页~~~~~~ */
/* ~~~~分页~~~~~~ */
/* ~~~~分页~~~~~~ */
.blog_page {
  margin-top: 10px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog_page a:hover {
  color: #fff !important;
  background-color: var(--hue);
}
.blog_page .paginationjs-prev {
  display: block;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.blog_page a {
  background-color: #f5f5f5;
  color: #666;
  font-size: clamp(0.875rem, 0.788rem + 0.38vw, 1.125rem);
  width: clamp(1.688rem, 1.506rem + 0.81vw, 2.313rem);
  height: clamp(1.688rem, 1.506rem + 0.81vw, 2.313rem);
  line-height: clamp(1.688rem, 1.506rem + 0.81vw, 2.313rem);
  margin: 0px clamp(0.313rem, 0.204rem + 0.48vw, 0.625rem);
  border-radius: 5px;
  text-align: center;
}

.blog_page .hover {
  color: #fff !important;
  background-color: var(--hue) !important;
}
/* ~~~~分页 end~~~~~~ */
/* ~~~~分页 end~~~~~~ */
/* ~~~~分页 end~~~~~~ */
/* ~~~~分页 end~~~~~~ */
/* ~~~~分页 end~~~~~~ */
/* ~~~~分页 end~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~导航,css */
/* ~~~~~~~~~~~~~~~~~~~导航,css */
.header .wrapper {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.header_box {
  height: 0;
}

/* 
.header.hover {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
	width: 100%;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
} */

.header {
  height: auto;
  position: fixed;
  left: 0;
  top: 0px;
  z-index: 80;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 20%);
}
/* 导航底部 */
.header_b {
  position: relative;
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  background-color: #ffffff20;
}

.header_b.hover {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 6%);
}

.header_b.hover .menu_tt {
  color: #333333;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.header_b.hover .menu_dl .hover .menu_tt {
  color: var(--hue);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.header_b.hover .header_language_01 .icon1,
.header_b.hover .header_language_01,
.header_b.hover .header_search {
  color: #666666;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.header.hover {
  position: relative;
}

.logo {
  width: 216px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.logo-white{
  /* add by chy 2023-10-09 08:57:33 */
  filter: contrast(0%) brightness(500%);
}

.logo img {
  width: 100%;
}

.logo2 {
  display: none;
}

.menu_dl_box {
  width: calc(100% - 216px);
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-end;
  -ms-justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.menu_dl {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.menu_dl dd {
  position: relative;
  /* margin-right: 2.08vw; */
  margin: 0 1vw;
}

.menu_down {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 350px;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding: 33px 30px;
  background-color: #fff;
  overflow: hidden;
  display: none;
}

.menu_down_list {
  width: 100%;
}

.menu_down_list a {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  position: relative;
  color: var(--color3);
  font-size: 16px;
  font-family: var(--fwr);
  padding-bottom: 20px;
  text-shadow: none;
}
.menu_down_list a:last-child {
  padding-bottom: 0;
}
.menu_down_list a .tt {
  width: calc(100% - 16px);
}
.menu_down_list a .dian {
  width: 0px;
  height: 6px;
  border-radius: 100%;
  margin-right: 0px;
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
}
.menu_down_list .hover {
  background-color: #fff;
  color: var(--hue);
}

.menu_down_list a:hover {
  color: var(--hue);
  font-family: var(--fwm);
}
.menu_down_list a:hover .dian {
  width: 6px;
  margin-right: 10px;
  opacity: 1;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.menu_tt {
  font-family: var(--fwr);
  height: 120px;
  color: #fff;
  display: block;
  white-space: nowrap;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.menu_tt_shadow{
  /* chy add 231106 */
  /* text-shadow: 1px 1px 1px #0c0000; */
}


.menu_dl .menu_tt:hover{
  color:var(--hue);
  text-shadow: none;
}

.menu_dl .hover .menu_tt {
  color:#fff;
  /* color: var(--hue); */
  /* font-weight: bold; */
  text-shadow: none;
  /* text-shadow: -.5px .5px 0 blue; */
}

.menu_dl .hover .menu_tt::before{
  position: absolute;

  content: " ";
  background-color: var(--hue);
  display: block;
  width:100%;
  height:2rem;

  transform: scale(1.3);

  z-index: -1;
}




/* 顶部搜索 */
.pc_head_cen_r_icon {
  width: 3.13vw;
}

.header_search {
  width: 100%;
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-end;
  -ms-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  cursor: pointer;
  color: #fff;
}

.header_search .click_icon {
  font-size: 28px;
}

/* 可公用导航搜索 */
.all_public_searchBox {
  display: none;
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.all_public_form {
  display: none;
  position: fixed;
  z-index: 21;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 800px;
}

.all_public_form input {
  background-color: #fff;
  width: 100%;
  outline: none;
  /* border: none; */
  box-sizing: border-box;
  padding: 20px 136px 20px 20px;
  /* border-radius: 10px 25px 10px 25px; */
}

.all_public_form select {
  background-color: #fff;
  outline: none;
  border: none;
  padding:0 10px;
  box-sizing: border-box;
  appearance: auto;
}

.all_public_form .icon_01 {
  font-size: 38px;
  cursor: pointer;
  position: absolute;
  top: 51%;
  right: 80px;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  outline: none;
  border: none;
  background: none;
  -ms-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #888888;
}

.all_public_form .icon_01:hover {
  color: var(--hue);
}

.all_public_form .public_form_close {
  font-size: 34px;
  position: absolute;
  top: 11px;
  right: 26px;
  -ms-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #888888;
  cursor: pointer;
}

.all_public_form .public_form_close:hover {
  color: var(--hue);
}
/* 可公用导航搜索~~~~~~~~~~~~~~~~~~结束 */
/* ~~~~~~~~~~~~~~~~pc 导航,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~~~~~pc 导航,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~~~~~pc 导航,css~~~~~~~~~~~end */

/* 点击视频弹出播放框css */
.ind_video_maskBox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.ind_video_maskBox .video_Box_1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -60%);
  -webkit-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  width: 800px;
  height: 485px;
}

.ind_video_maskBox .video_Box_1 .videoIframe {
  background: #333333;
  width: 100%;
  height: 100%;
  border: none;
}

.ind_video_maskBox .video_Box_1 .closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  -ms-transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ind_video_maskBox .video_Box_1 .closeBtn:hover {
  color: #f40a0a;
}

/* 点击视频弹出播放框css~~~~~~~~~~~end */

/* ~~~~~~~~~~~~~~~footer,css */
/* ~~~~~~~~~~~~~~~footer,css */
/* ~~~~~~~~~~~~~~~footer,css */

.footer {
  position: relative;
  background-color: #00192b;
}
.footer footer {
  position: relative;
  width: 100%;
  height: 100%;
}
.footer_t {
  padding: 4.69vw 0;
}

.footer_t .share__list a {
  background-color: #fff;
}
.footer_t .wrapper {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.footer_t_l {
  width: 23%;
}

.footer_t_l_logo {
  display: block;
  margin-bottom: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
}

.footer_t_l_text {
  color: #fff;
  line-height: 1.6;
  margin-bottom: clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
}

.footer_t_l_sm {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
}

.footer_t_l_sm a {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  font-size: 20px;
  border-radius: 100%;
  margin: 0 13px 0 0;
}

.footer_t_l_sm a:hover {
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.footer_t_r {
  width: 70%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.footer_t_r_item {
  width: 30%;
}
.footer_t_r_item:nth-child(2) {
  width: 40%;
}
.footer_t_r_item_tt {
  color: #fff;
  margin-bottom: 15px;
}

.footer_t_r_item_list a {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0;
}
.footer_t_r_item_list a .dian {
  width: 0px;
  height: 6px;
  margin-right: 0px;
  border-radius: 100%;
  background-color: #fff;
}
.footer_t_r_item_list a .tt {
  width: calc(100% - 11px);
}
.footer_t_r_item_list a:hover .dian {
  width: 6px;
  margin-right: 6px;
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.footer_t_r_item_list a:hover {
  color: var(--hue);
  transform: translateX(3px);
  -webkit-transform: translateX(3px);
}

.footer_t_r_item_list2 .item {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 0;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.footer_t_r_item_list2 .item .iconfont {
  margin-right: 10px;
}
.footer_t_r_item_list2 .item a {
  color: #fff;
}

.footer_t_r_item_list2 .item a:hover {
  text-decoration: underline;
}

.footer_b {
  border-top: 1px solid rgba(255, 255, 255, 20%);
}

.footer_b .wrapper {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding: clamp(0.875rem, 0.621rem + 1.13vw, 1.75rem) 0;
}

.footer_b_text {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
.footer_b_text a {
  color: #fff;
}
.footer_b_text a:hover {
  text-decoration: underline;
}
/* ~~~~~~~~~~~~~~~footer,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~~~~footer,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~~~~footer,css~~~~~~~~~~~end */

/*~~~~~~~~~~~~回到顶部    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~回到顶部    ~~~~~~~~~~~*/

/*~~~~~~~~~~~~回到顶部 下面是原版    ~~~~~~~~~~~*/
/* 
.float_fixed_top {
}

.float_fixed_top .item {
  position: relative;
}

.float_fixed {
  position: fixed;
  right: 1rem;
  bottom: 40%;
  transform: translate(0%, 50%);
  -ms-transform: translate(0%, 50%);
  -webkit-transform: translate(0%, 50%);
  z-index: 3;
}

.float_fixed_list {
}

.float_fixed .item {
  background-color: #fff;
  color: var(--hue);
  border-radius: 5px;
  margin-bottom: 10px;
  display: block;
  width: clamp(1.875rem, 1.694rem + 0.81vw, 2.5rem);
  height: clamp(1.875rem, 1.694rem + 0.81vw, 2.5rem);
  line-height: clamp(1.875rem, 1.694rem + 0.81vw, 2.5rem);
  text-align: center;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 10%);
}

.float_fixed_list .item .item_con {
  position: absolute;
  top: 0;
  right: 0%;
  width: 0px;
  border-radius: 5px;
  color: var(--hue);
  font-size: 16px;
  box-shadow: -2px 3px 5px rgba(0, 0, 0, 0.15);
  background: #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -ms-transform: translate(110%, 0);
  -webkit-transform: translate(110%, 0);
  transform: translate(110%, 0);
  height: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
}

.float_fixed_list .item:hover .item_con {
  width: 255px;
  -ms-transform: translate(1px, 0);
  -webkit-transform: translate(1px, 0);
  transform: translate(1px, 0);
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.float_fixed_list .item .item_con .icon {
  width: 80px;
  height: clamp(2.875rem, 2.659rem + 0.96vw, 3.5rem);
  color: var(--hue);
  line-height: clamp(2.875rem, 2.659rem + 0.96vw, 3.5rem);
  position: relative;
}


.back_top {
  display: block;
  font-weight: 900;
}

*/
/*~~~~~~~~~~~~回到顶部 上面是原版    ~~~~~~~~~~~*/



.kefu-chy{
  position: fixed;
  top: 50%;
  right: .3rem;
  transform: translateY(-50%);

  /* display: flex; */
  flex-direction: column;
  gap:.3rem;
  z-index: 100;

  --bg:#eee;
  --color:var(--hue);
  --color-hover:red;
  /* var(--xx, red, blueviolet, --hue3, green); */
  
}


/* 一级子节点 */
.kefu-chy > .item-box{
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:.5rem;

  padding:.5rem;
  min-width:5rem;
  min-height:5rem;

  color:var(--color);
  border-radius: 10%;
  background-color: var(--bg);
  /* box-shadow: 0 0 1px #888; */
  cursor: pointer;
 
}

.kefu-chy > .item-box:hover{
  color:var(--color-hover);
}

/* 抽屉 */
.kefu-chy .drawer-box{
  position: absolute;
  left:0;
  top:50%;
  transform: translate(0, -50%) scale(0);

  padding-right: 10px;

  transition: transform ease .2s;

  z-index: -1;
}

/* 注意：只有一级子节点为div时才有抽屉 */
.kefu-chy > .item-box:hover .drawer-box, .kefu-chy li .now{
  transform: translate(-100%, -50%) scale(1);
}

.kefu-chy .drawer-conent-box{
  position: relative;
  color:var(--color-hover);
  background-color: var(--bg);
  padding:1rem;
}


/* 右侧箭头 */
.kefu-chy .drawer-conent-box::after{
  position: absolute;
  top:50%;
  right:0;
  content:" ";
  display: block;
  width:1rem;
  height:1rem;
  background-color: var(--bg);
  transform: translate(30%, -50%) rotate(45deg);
}


.item-box .iconfont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:.5rem;
  font-size: 14px;
  line-height: 1;
}

/* 图标25px */
.item-box .iconfont::before{
  font-size: 2.5em;
}


.btm-bar > .iconfont{
  font-size: 24px !important;
  line-height: 1.2;
}



/*~~~~~~~~~~~~回到顶部   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~回到顶部   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~回到顶部   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~  public_herf  ~~~~~~~~~~~*/
/*~~~~~~~~~~~~  public_herf  ~~~~~~~~~~~*/
/*~~~~~~~~~~~~  public_herf  ~~~~~~~~~~~*/

.Crumbs_box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}

.Crumbs_box .iconfont {
  font-size: 18px;
  color: #fff;
}

.Crumbs_box .a_box {
  font-size: 16px;
  color: #fff;
  padding: 0px 0 0 6px;
}

.Crumbs_box .a_box a {
  color: #fff;
  font-size: 14px;
  font-family: var(--fwr);
}

.Crumbs_box .a_box a:hover {
  text-decoration: underline;
}

/*~~~~~~~~~~~~ public_herf  end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ public_herf  end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ public_herf  end ~~~~~~~~~~~*/

/* ~~~~~~~~~~~~~~~wap head,css */
/* ~~~~~~~~~~~~~~~wap head,css */
/* ~~~~~~~~~~~~~~~wap head,css*/

.mask,
.mobile_menu_mask {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.mask {
  z-index: 13;
}

/*
 * wap head
 */
.mobile_header {
  height: 52px;
  display: none;
  clear: both;
  width: 100%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.mobile_header_top {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  width: 100%;
  height: 52px;
  background: #fff;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.2);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.mobile_logo {
  height: 52px;
  margin-left: 0;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  padding: 0 15px;
}

.mobile_logo img {
  height: 42px;
  width: auto;
}

.mobile_menu_btn {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  width: 54px;
  height: 52px;
  cursor: pointer;
}

.mobile_menu_btn .line {
  display: block;
  height: 3px;
  width: 28px;
  background: var(--hue);
  border-radius: 4px;
  margin-bottom: 6px;
}

.mobile_menu_btn .line:last-child {
  margin-bottom: 0;
}

.mobile_search_icon {
  width: 40px;
  height: 52px;
  color: #222;
  font-size: 22px;
  line-height: 52px;
  text-align: center;
  margin-right: 52px;
  cursor: pointer;
}

.mobile_menu_b {
  margin-top: 40px;
}

.mobile_menu_b_clm {
  margin-bottom: 25px;
}

.mobile_menu_b_clm .tt {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.mobile_menu_b_clm .text {
  font-size: 14px;
  color: #333;
}

.mobile_menu_b_clm .text a {
  color: #333;
  word-break: break-all;
}

.mobile_lag_box {
  padding: 30px 0 20px;
  margin-bottom: 40px;
}

.mobile_lag_tt {
  font-size: 16px;
  color: #333;
  padding-bottom: 18px;
}

.mobile_lag_text {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.mobile_lag_text a {
  color: #333;
  font-size: 16px;
  margin-right: 25px;
}

.mobile_menu_box {
  position: fixed;
  right: -110%;
  top: 0;
  /* z-index: 15; */
  z-index: 1000;
  width: 75%;
  height: 100%;
  background: #fff;
  padding: 20px 15px;
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.mobile_menu_box.show {
  right: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.mobile_menu_dl dd {
  clear: both;
  line-height: 1.3;
  border-bottom: 1px solid #e1e1e1;
}

.mobile_menu_tt {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.mobile_menu_tt .text {
  width: calc(100% - 50px);
  color: #333;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: uppercase;
}

.mobile_menu_dl dd.hover .mobile_menu_tt .text {
  color: var(--hue);
}

.mobile_menu_tt_icon {
  /* display: none; */
  width: 44px;
  line-height: 44px;
  text-align: center;
  color: #333;
  font-size: 16px;
  -webit-transition: all 0.5s;
  transition: all 0.5s;
  transform: rotateZ(-90deg);
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  -ms-transform: rotateZ(-90deg);
  -o-transform: rotateZ(-90deg);
  cursor: pointer;
}

.mobile_menu_down {
  display: none;
  padding: 6px 0 10px 12px;
}

.mobile_menu_down_clm {
  margin-bottom: 8px;
}

.mobile_menu_down_clm_tts {
  max-width: 65%;
  font-family: "Poppins" !important;
}

.mobile_menu_down_clm_tt {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
}

.mobile_menu_down_clm_tt .tt {
  width: calc(100% - 50px);
  color: #333;
  line-height: 20px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 0;
}

.mobile_menu_down_clm_tt .icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webit-transition: all 0.5s;
  transition: all 0.5s;
}

.mobile_menu_down_clm.hover .mobile_menu_down_clm_tt .icon {
  -webit-transition: all 0.5s;
  transition: all 0.5s;
  transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
}

.mobile_menu_down_clm_list {
  display: none;
  padding: 5px 0 0 10px;
}

.mobile_menu_down_clm_list a {
  display: block;
  font-size: 12px;
  line-height: 1.4;
  color: #333;
  padding: 5px 0 5px 14px;
  position: relative;
}

.mobile_menu_down_clm_list a::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 5px;
  margin-top: -3px;
  background: #333;
  border-radius: 50%;
}

.mobile_menu_tt_icon.hover {
  transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -webit-transition: all 0.5s;
  transition: all 0.5s;
}

/* ~~~~~~~~~~~~~~~wap head,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~~~~wap head,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~~~~wap head,css~~~~~~~~~~~end */
/* ~~~~~~~~~~~~public~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~public ~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~public ~~~~~~~~~~~~~~~ */
/* 公用btn */
.public_btn {
  display: block;
  width: clamp(10rem, 9.637rem + 1.61vw, 11.25rem);
  height: clamp(2.5rem, 2.319rem + 0.81vw, 3.125rem);
  border-radius: 100px;
  z-index: 1;
  text-align: center;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  background-color: var(--hue);
  color: #fff;
}
.public_btn .icon1 {
  margin-right: 0.52vw;
  font-size: clamp(1.125rem, 1.016rem + 0.48vw, 1.5rem);
}

.public_btn .icon2 {
  margin-left: 6px;
}

.public_btn:hover {
  color: #fff;
}

.text {
  line-height: clamp(1.5rem, 1.391rem + 0.48vw, 1.875rem);
}

/* 标题 */
.public_module_title .subtitle {
  padding-bottom: 25px;
}

.public_module_title .title {
  padding-bottom: 20px;
}

.public_module_title .text {
  line-height: clamp(1.5rem, 1.391rem + 0.48vw, 1.875rem);
  max-width: 968px;
  margin: auto;
}

.public_module_title .line {
  width: 32px;
  height: 4px;
  background-color: var(--hue);
  margin-bottom: 10px;
}

.public_module_title .text1 {
  padding-top: 30px;
  line-height: 30px;
}
.p_b_40 {
  padding-bottom: clamp(1.25rem, 0.887rem + 1.61vw, 2.5rem);
}
/* ~~~~~~~~~~~~public end~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~public end~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~public end~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~popup inquiry,css */
/* ~~~~~~~~~~~~popup inquiry,css */
/* ~~~~~~~~~~~~popup inquiry,css */

.popup_inquiry {
  display: none;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup_inquiry_mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 37%);
  cursor: pointer;
}

.popup_inquiry_form {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 52%;
  max-width: 700px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.popup_inquiry_form_close {
  position: absolute;
  top: -5%;
  right: 0px;
  cursor: pointer;
  color: #fff;
  font-size: 28px;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
.popup_inquiry_form_close:hover {
  color: var(--hue);
}
.click_popup_inquiry {
  cursor: pointer;
}
.popup_inquiry_box {
  background-color: #fff;
  width: 700px;
  margin: auto;
  border-radius: 20px;
  padding: clamp(1.25rem, 0.887rem + 1.61vw, 2.5rem)
    clamp(1.875rem, 1.331rem + 2.42vw, 3.75rem);
}
.popup_inquiry_box form {
  width: 100%;
}
.popup_inquiry_box .item {
  margin-bottom: 20px;
}
.popup_inquiry_box .item .title {
  margin-bottom: 10px;
}
.popup_inquiry_box .item input {
  height: 60px;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: 100%;
  border: none;
  font-size: 16px;
  color: #333;
  font-family: var(--fwr);
  padding-left: 15px;
}
.popup_inquiry_box .item.textarea textarea::placeholder,
.popup_inquiry_box .item input::placeholder {
  color: #666666;
  font-size: 14px;
  font-family: var(--fwr);
}
.popup_inquiry_box .item.textarea textarea {
  height: 100px;
  resize: none;
  background-color: #f5f5f5;
  padding-left: 15px;
  border: none;
  width: 100%;
  padding-top: 15px;
}
.popup_inquiry_box .item.submit input {
  display: inline-block;
  width: 180px;
  height: clamp(2.5rem, 2.319rem + 0.81vw, 3.125rem);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-family: var(--fwm);
  background-color: var(--hue);
}
/* ~~~~~~~~~~~~popup inquiry,css~~~~~~~~~end */
/* ~~~~~~~~~~~~popup inquiry,css~~~~~~~~~end */
/* ~~~~~~~~~~~~popup inquiry,css~~~~~~~~~end */
/*~~~~~~~~~~~~ index   ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ index  ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ index   ~~~~~~~~~~~*/
.banner_box {
  width: 100%;
  height: 100%;
}
.banner-video{
  object-fit: fill;
  filter: brightness(60%);
}

.banner {
  clear: both;
  width: 100%;
  height: 100%;
}

.banner .item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: relative;
}
.banner .swiper-slide-active .item .index_banner_item {
  -webkit-animation: fadeInLeft 1.8s both;
  animation: fadeInLeft 1.8s both;
}
.index_banner_item {
  padding-top: 13.18vw;
}
.index_banner_item .title {
  text-shadow: 0 4px 6px rgba(0, 56, 97, 30%);
  width: 60%;
  line-height: 1.3;
}
.index_banner_item .subtitle {
  padding-top: 13.13vw;
  font-size: 6.25vw;
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}
/* index_one */
.index_one {
  width: 100%;
}
.aboBoxFour2 {
  border-radius: 10px;
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  overflow: hidden;
}

.aboBoxFour2 .item {
  position: relative;
  width: calc((100% - 20%) / 4);
  height: 510px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  z-index: 2;
}
.aboBoxFour2 .item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 38, 67, 100%);
  opacity: 12%;
}
.aboBoxFour2 > .item:last-child {
  margin-right: 0;
}
.aboBoxFour2 .item .item_content {
  width: 100%;
  height: 100%;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: flex-end;
  -ms-align-items: flex-end;
  -webkit-align-items: flex-end;
}
.aboutus_two_title {
  padding: 2.6vw 2.34vw;
  width: 95%;
}
.aboutus_two_title .icon {
  max-width: 31px;
  margin-bottom: 10px;
}
.aboutus_two_title .icon img {
  width: 100%;
  height: 100%;
}

.aboBoxFour2 .item .item_content .aboutus_two_icon {
  font-size: 100px;
  color: #ffffff;
  padding-bottom: 0.78vw;
}
.aboBoxFour2 .item .item_content .text {
  display: none;
  color: #ffffff;
  padding-top: 10px;
}

.aboBoxFour2 .item.hover {
  width: calc(100% - 1px * 4 - (15% * 4));
}
.aboBoxFour2 .item.hover .aboutus_two_title {
  padding-left: 3.91vw;
}
.aboBoxFour2 .item.hover::before {
  opacity: 0;
}
.aboBoxFour2 .item.hover .item_content {
  width: 100%;
  text-align: left;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-start;
  -ms-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: flex-end;
  -ms-align-items: flex-end;
  -webkit-align-items: flex-end;
}
.aboutus_two_tt {
  width: 100%;
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  position: relative;
}
.aboBoxFour2 .item.hover .item_content .text {
  display: block;
}
/* index_two */
.index_two {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.index_two {
  width: 100%;
  height: calc(100% - 120px);
}
.viewport-cont {
  width: 100%;
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.index_two {
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.index_two_l {
  width: 30%;
}
.index_two_r {
  width: 64%;
}
.index_two_width {
  position: relative;
  width: calc((100vw - 10px) / 2 + 50%);
  z-index: 2;
}
.index_two_l .title {
  margin-bottom: clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
}
.index_two_l .text {
  margin-bottom: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
}
.index_two_list .item {
  margin-bottom: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
}
.index_two_list .item .number {
  margin-right: 10px;
}
.index_two_list .item .tt {
  width: calc(100% - 15px);
  line-height: 1.3;
}

.index_two_item {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
  background-color: #fff;
  display: block;
  border-radius: 10px;
}
.index_two_item .pic {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.index_two_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_two_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_two_item .title {
  padding: clamp(0.625rem, 0.353rem + 1.21vw, 1.563rem);
}
.index_two_swiper_btn {
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 2;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.swiper_btn.next,
.swiper_btn.prev {
  width: clamp(2.313rem, 2.04rem + 1.21vw, 3.25rem);
  height: clamp(2.313rem, 2.04rem + 1.21vw, 3.25rem);
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  color: #666;
  cursor: pointer;
}
.swiper_btn.prev {
  margin-bottom: 1.56vw;
}
.swiper_btn.next {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.swiper_btn:hover,
.swiper_btn.hover {
  color: #fff;
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/* index_three */
.index_three {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  position: relative;
}
/* .index_three_swiper {
  padding-bottom: 55px;
} */
.index_three_item {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
  background-color: #fff;
}
.index_three_item .pic {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.index_three_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_three_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_three_item .bottom {
  padding: clamp(0.938rem, 0.575rem + 1.61vw, 2.188rem)
    clamp(1.25rem, 0.796rem + 2.02vw, 2.813rem);
  text-align: center;
}
.index_three_item .title {
  margin-bottom: 10px;
}
.index_three_item .text {
  margin-bottom: 15px;
}
.index_three_item .public_btn {
  width: 170px;
  height: 42px;
  color: #fff;
  border-radius: 100px;
  overflow: hidden;
  margin: auto;
  display: none;
}
.swiper-slide-active .index_three_item .public_btn {
  display: block;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
/* index_four */
.index_four {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-end;
  -ms-align-items: flex-end;
  -webkit-align-items: flex-end;
}
.index_four_con {
  padding-bottom: 55px;
}
.index_four_t .text {
  margin-bottom: 15px;
}
.index_four_t .public_btn {
  width: 162px;
  color: var(--hue);
  background-color: transparent;
  border: 1px solid var(--hue);
  border-radius: 100px;
  margin: auto;
}
.index_four_t .public_btn:hover {
  background-color: var(--hue);
  color: #fff;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.index_four_t {
  padding-bottom: 11.46vw;
}
.index_four_list {
  width: calc(100% - 10.42vw);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin: auto;
}
.index_four_item {
  width: calc((100% - 21.25vw) / 4);
  background-color: rgba(255, 255, 255, 90%);
  margin-right: 7.08vw;
  padding: clamp(0.938rem, 0.575rem + 1.61vw, 2.188rem)
    clamp(0.625rem, 0.353rem + 1.21vw, 1.563rem)
    clamp(0.625rem, 0.353rem + 1.21vw, 1.563rem)
    clamp(0.938rem, 0.575rem + 1.61vw, 2.188rem);
  border-radius: 10px;
}
.index_four_item:hover .tt,
.index_four_item:hover .counter,
.index_four_item:hover .iconfont {
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.index_four_item .iconfont {
  text-align: right;
  margin-bottom: clamp(0.813rem, 0.577rem + 1.05vw, 1.625rem);
}
.index_four_item:nth-child(4n) {
  margin-right: 0;
}
.index_four_item .tt {
  margin-top: 10px;
}
/* index_five */
.index_five{
  width: 100%;
}
.index_five_l .pic {
  width: 100%;
  overflow: hidden;
}
.index_five_l .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_five_l:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_five_l {
  width: 44.5%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  z-index: 2;
}
.index_five_r {
  width: 52%;
}
.index_five_l .trim {
  position: absolute;
  top: 0;
  left: 0;
  padding: clamp(0.5rem, 0.337rem + 0.73vw, 1.063rem)
    clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
  background-color: rgba(0, 0, 0, 25%);
  border-radius: 0 0 10px 0;
}
.index_five_l .title {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-bottom: clamp(0.813rem, 0.577rem + 1.05vw, 1.625rem);
}
.index_five_l::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background-image: linear-gradient(
    to top,
    rgba(0, 90, 156, 30%),
    rgba(0, 90, 156, 0%)
  );
  z-index: 1;
}
.index_five_b {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.index_five_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 1.82vw;
}
.index_five_item:last-child {
  margin-bottom: 0;
}
.index_five_item .trim {
  margin-bottom: 10px;
}
.index_five_item .title {
  margin-bottom: 10px;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.index_five_item .pic {
  width: 25%;
  border-radius: 10px;
  overflow: hidden;
}
.index_five_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.index_five_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.index_five_item .con {
  width: 72%;
}
.index_five_item:hover .title {
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/* footer_from */
.footer_from {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3.91vw 0;
  z-index: 2;
}
.footer_from::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 40, 68, 89%);
  z-index: -1;
}
.footer_from_l {
  width: 25%;
}
.footer_from_r {
  width: 75%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-end;
  -ms-justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.footer_from_l .title {
  margin-bottom: 15px;
}
.footer_from_box form {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  -ms-justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.footer_from_box .item {
  margin-left: 1.04vw;
  margin-bottom: 10px;
}
.footer_from_box .item input {
  height: clamp(2.5rem, 2.319rem + 0.81vw, 3.125rem);
  border-radius: 100px;
  padding-left: 1.41vw;
  background-color: rgba(255, 255, 255, 40%);
  border: none;
  width: clamp(11.25rem, 10.76rem + 2.18vw, 12.938rem);
  color: #fff;
  font-size: 16px;
  font-family: var(--fwr);
}
.footer_from_box .item input::placeholder {
  color: #fff;
  font-size: 14px;
  font-family: var(--fwr);
}
.footer_from_box .item.messgaes input {
  width: clamp(12.5rem, 11.339rem + 5.16vw, 16.5rem);
}
.footer_from_box .item.submit input {
  width: 155px;
  padding-left: 0;
  background-color: #fff;
  color: var(--hue);
  cursor: pointer;
}
/* 动画 */

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

/*~~~~~~~~~~~~ index  end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ index end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ index  end ~~~~~~~~~~~*/

/*~~~~~~~~~~~~factory_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~factory_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~factory_list    ~~~~~~~~~~~*/
.factory_list_t {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
}
.factory_list_table {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  overflow-x: auto;
}
.solution_list_table::-webkit-scrollbar,
.factory_list_table::-webkit-scrollbar {
  height: 3px;
}
.factory_list_table .item {
  white-space: nowrap;
  height: 6.77vw;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-right: 8.33vw;
  font-family: var(--fwm);
}
.factory_list_table .item .tt {
  margin-left: 5px;
}
.factory_list_table .item:last-child {
  margin-right: 0;
}
.factory_list_table .item:hover,
.factory_list_table .item.hover {
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.factory_list_dl {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.factory_list_dl dd {
  width: calc((100% - 5.21vw) / 3);
  margin-bottom: 2.6vw;
  margin-right: 2.6vw;
}
.factory_list_dl dd:nth-child(3n) {
  margin-right: 0;
}
/*~~~~~~~~~~~~factory_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~factory_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~factory_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~patent_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~patent_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~patent_list    ~~~~~~~~~~~*/
.patent_list_t {
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 8%);
}
.patent_list_table {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  overflow-x: auto;
}
.patent_list_table::-webkit-scrollbar {
  height: 3px;
}
.patent_list_table .item {
  text-transform: uppercase;
  white-space: nowrap;
  height: 6.77vw;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-right: 8.33vw;
  font-family: var(--fwm);
}
.patent_list_table .item .tt {
  margin-left: 5px;
}
.patent_list_table .item:last-child {
  margin-right: 0;
}
.patent_list_table .item:hover,
.patent_list_table .item.hover {
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.patent_list_item {
  display: block;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 10%);
}
.patent_list_item .pic {
  position: relative;
  padding: clamp(0.625rem, 0.3rem + 1.44vw, 1.563rem);
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
}
.patent_list_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.patent_list_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.patent_list_item .bottom {
  color: #333;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: clamp(0.625rem, 0.344rem + 1.25vw, 1.438rem)
    clamp(0.625rem, 0.3rem + 1.44vw, 1.563rem);
}

.patent_list_dl {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.patent_list_dl dd {
  width: calc((100% - 7.81vw) / 4);
  margin-bottom: 2.6vw;
  margin-right: 2.6vw;
}
.patent_list_dl dd:nth-child(4n) {
  margin-right: 0;
}
/*~~~~~~~~~~~~patent_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~patent_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~patent_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~about    ~~~~~~~~~~~*/
.about_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: clamp(12.5rem, 5.242rem + 32.26vw, 37.5rem);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  position: relative;
  z-index: 3;
}
.about_banner_con {
  padding-top: 6.25vw;
  text-shadow: 0 0 10px black;
}
.about_banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 90, 156, 100%);
  opacity: 15%;
  z-index: -1;
}
/* about_one */
.about_one_con {
  padding: 0 2vw;
}
.about_one_l {
  width: 38%;
  position: relative;
}
.about_one_r {
  width: 50%;
  position: relative;
}
.about_one_l::before {
  content: "";
  position: absolute;
  top: -22px;
  left: -22px;
  border-radius: 10px;
  border: 3px solid #f5f5f5;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.about_one_l .max_pic {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.about_one_l .max_pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.about_one_l:hover .max_pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.about_one_l .min_pic {
  position: absolute;
  bottom: 4.22vw;
  right: 0px;
  transform: translate(50%, 0%);
  -ms-transform: translate(50%, 0%);
  -webkit-transform: translate(50%, 0%);
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  width: clamp(6.438rem, 5.349rem + 4.84vw, 10.188rem);
}
.about_one_l .min_pic img {
  width: 100%;
  height: 100%;
}
.about_one_l .video_icon {
  width: clamp(1.688rem, 1.325rem + 1.61vw, 2.938rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border-radius: 100%;
  overflow: hidden;
}
.about_one_r .title {
  margin-bottom: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
}
.about_one_r .text {
  margin-bottom: clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
}
.about_one_r .bottom {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.about_one_tel .iconfont {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  border: 1px solid var(--hue);
  color: var(--hue);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin-right: 10px;
}
.about_one_tel {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-left: 2.81vw;
}
.about_one_tel .tel {
  margin-bottom: 0.52vw;
}
.about_one_r .left {
  position: relative;
}
.about_one_r .img {
  position: absolute;
  top: -60%;
  left: -75%;
}
/* about_two */
.suppBoxThree1 {
  border-radius: 10px;
  background: #f5f5f5;
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}

.suppThree1_L {
  width: 37.5%;
}
.suppThree1_L_item {
  height: 100%;
  padding-left: 7.55vw;
  padding-right: 5.21vw;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.suppThree1_L_item .icon {
  margin-bottom: 15px;
}
.suppThree1_L_item .title {
  color: #222222;
  margin-bottom: 10px;
}
.suppThree1_R {
  width: 62.5%;
  height: clamp(18.75rem, 11.492rem + 32.26vw, 43.75rem);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
.suppThree1_R::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 19%);
  z-index: 1;
}
.suppThree1_R .item_box {
  width: calc(100% / 3);
  border-right: 1px solid rgba(255, 255, 255, 20%);
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.suppThree1_R .item_box.hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 22%);
}
.cooperation_one_b_item {
  position: relative;
  z-index: 2;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.cooperation_one_b_item .title {
  width: 100%;
  text-align: center;
}
.cooperation_one_b_item .item .item_dian {
  width: auto;
  height: auto;
  text-align: center;
  margin-bottom: clamp(0.938rem, 0.829rem + 0.48vw, 1.25rem);
}
.cooperation_one_b_item .item .item_dian img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.suppThree1_L .title2 {
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
/* about_four */
.about_four_top {
  position: relative;
  margin-bottom: 40px;
}
.about_four_top::before {
  content: url(../images/about_four_line.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  bottom: 4px;
  left: -10%;
  width: calc((100% - 10px) / 2 + 100%);
}
.about_four_t_item {
  text-align: center;
  cursor: pointer;
}
.about_four_t_item .number {
  font-size: clamp(1.25rem, 1.177rem + 0.32vw, 1.5rem);
  font-family: var(--fwr);
  color: #333;
  margin-bottom: 7px;
  height: 46px;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-align-items: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.about_four_t_item .dian {
  margin: auto;
  width: 17px;
  height: 17px;
  border: 1px solid var(--hue);
  border-radius: 100%;
  background-color: #fff;
}
.about_four_t_swiper_btn {
  font-size: 20px;
  position: absolute;
  bottom: -8px;
  width: 35px;
  height: 35px;
  background-color: #f5f5f5;
  color: #333;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  border-radius: 100%;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  z-index: 6;
  cursor: pointer;
}
.about_four_t_swiper_btn:hover {
  color: #fff;
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.about_four_t_swiper_btn.next {
  right: 0;
  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}
.about_four_t_swiper {
  width: calc((100% - 10.42vw));
}
.about_four_t_swiper .swiper-slide-active .about_four_t_item .number {
  font-size: clamp(1.25rem, 0.924rem + 1.37vw, 2.125rem);
  font-family: var(--fwm);
}
.about_four_t_swiper .swiper-slide-active .about_four_t_item .dian {
  background-color: var(--hue);
}
.about_four_b_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}
.about_four_b_item .left {
  width: 48%;
}
.about_four_b_item .left .pic {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
}
.about_four_b_item .left .pic img {
  width: 100%;
  height: 100%;
}
.about_four_b_item .right {
  width: 44%;
  padding-right: 40px;
}
.about_four_b_item .right .number {
  margin-bottom: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
}
.about_four_b_item .right .btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  border-radius: 5px;
  border: 1px solid var(--hue);
  width: 100px;
}
.about_four_b_swiper_btn {
  font-size: 32px;
  color: var(--hue);
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  width: 50px;
  text-align: center;
  cursor: pointer;
}
.about_four_b_swiper_btn:last-child {
  border-left: 1px solid var(--hue);
}
.about_four_b_swiper_btn:first-child {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
/* about_five */
.about_five {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.about_five_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.about_five_item {
  display: block;
  width: calc((100% - (clamp(0.938rem, 0.613rem + 1.44vw, 1.875rem) * 2)) / 3);
  margin: 0 clamp(0.938rem, 0.613rem + 1.44vw, 1.875rem)
    clamp(0.938rem, 0.613rem + 1.44vw, 1.875rem) 0;
  position: relative;
  border-radius: 10px;
}
.about_five_item:nth-child(3n) {
  margin-right: 0;
}
.about_five_item .img {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-top: 1.56vw;
}
.about_five_item .img .pic {
  width: 100%;
  height: 100%;
  max-width: 140px;
  margin-right: clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem);
}
.about_five_item .img .pic img {
  width: 100%;
  height: 100%;
}
.about_five_item img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
/* about_six */
.about_six_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.about_six_item {
  width: calc((100% - 5.21vw) / 3);
  position: relative;
  text-align: center;
  margin-right: 2.6vw;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
  padding: clamp(1.25rem, 0.706rem + 2.42vw, 3.125rem)
    clamp(0.938rem, -0.151rem + 4.84vw, 4.688rem);
}
.about_six_item:nth-child(2n) {
  margin-right: 0;
}
.about_six_item .bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 24, 48, 27%);
}
.about_six_item .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.about_six_item .bg img {
  width: 100%;
  height: 100%;
}
.about_six_item .pic {
  margin-bottom: clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem);
}
.about_six_item .pic img {
  margin:0 auto;
}
.about_six_item .title {
  margin-bottom: 0.78vw;
}
.about_six_item .hide {
  display: none;
}
.about_six_item:hover .text,
.about_six_item:hover .title {
  color: #fff;
}

.about_six_item:hover .bg {
  opacity: 1;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.about_six_item:hover .show {
  display: none;
}
.about_six_item:hover .hide {
  display: block;
  /* margin: auto; */
}
.about_six_item:hover .line {
  background-color: #fff;
}
/* about_eight*/
.about_eight_item {
  display: block;
  position: relative;
  overflow: hidden;
}
.about_eight_item .pic {
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.about_eight_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.about_eight_item:hover .pic img{
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.about_eight_item .title {
  margin-top: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
}
.about_eight_swiper_pagination {
  margin-top: 40px;
}
/* about_seven */
.about_box5_scroll_item_l {
  width: calc((100% - 1.56vw) / 2);
}
.about_box5_scroll_item_l .item {
  border-radius: 10px;
  overflow: hidden;
}
.about_box5_scroll_item_r {
  width: calc((100% - 1.56vw) / 2);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.about_box5_scroll_item_r .item {
  width: calc((100% - 30px) / 2);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1.56vw;
}
.about_box5_scroll_item_r .item:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
.about_seven_b .item a {
  display: block;
  width: 100%;
}
.about_seven_b .item img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.about_seven_b .item:hover img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
/*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~about   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_del    ~~~~~~~~~~~*/
.pro_del_one {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding: clamp(2.5rem, 1.418rem + 4.81vw, 5.625rem) 0;
}
.pro_deOne1_L {
  width: 45%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}
.pro_del_one_bottom {
  width: calc(25% - 2.08vw);
  margin-right: 2.08vw;
  overflow: hidden;
}
.pro_page_table {
  overflow-x: auto;
}
.pro_deOne1_L_dots {
  width: 100%;
  height: calc(27.4vw - 2.86vw);
  overflow: hidden;
}
.pro_deOne1_L_dots .item {
  height: 7.14vw;
  border: 1px solid transparent;
  overflow: hidden;
  cursor: pointer;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  position: relative;
  border-radius: 10px;
}
.pro_deOne1_L_dots .item img {
  width: 100%;
  height: 100%;
}

.pro_del_one_swiper_button {
  width: 100%;
  height: clamp(1.25rem, 0.978rem + 1.21vw, 2.188rem);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin-top: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
  font-size: clamp(1.125rem, 0.944rem + 0.81vw, 1.75rem);
  color: #000;
  cursor: pointer;
  background-color: #fff;
}
.pro_deOne1_L_dots .item:hover,
.pro_deOne1_L_dots .swiper-slide-thumb-active .item {
  border-color: var(--hue);
}

.pro_del_one_swiper_button.hover {
  color: var(--hue);
}

.pro_deOne1_L_lunbo {
  width: 73%;
  overflow: hidden;
}

.pro_deOne1_L_lunbo .swiper-slide .item {
  border-radius: 10px;
  overflow: hidden;
}

.pro_deOne1_L_lunbo .swiper-slide .item img {
  width: 100%;
  height: 100%;
}

.pro_deOne1_L_dots .hover.item::before {
  background-color: transparent;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.pro_deOne1_R {
  width: 50%;
}

.pro_deOne1_R .text_box {
  padding-top: clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
  line-height: 24px;
}

.pro_deOne1_R dl {
  padding: clamp(0.938rem, 0.613rem + 1.44vw, 1.875rem) 0;
  position: relative;
  margin: clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem) 0
    clamp(0.938rem, 0.613rem + 1.44vw, 1.875rem) 0;
}
.pro_deOne1_R dl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 50%,
    #ccc 50%,
    #ccc 100%
  );
  background-size: 15px 1px;
  background-repeat: repeat-x;
}
.pro_deOne1_R dl::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to left,
    transparent 0%,
    transparent 50%,
    #ccc 50%,
    #ccc 100%
  );
  background-size: 15px 1px;
  background-repeat: repeat-x;
}

.pro_deOne1_R dl dd {
  margin-bottom: clamp(0.625rem, 0.5rem + 0.63vw, 1.25rem);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.pro_deOne1_R dl dd:last-child {
  margin-bottom: 0;
}
.pro_deOne1_R dl dd .item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.pro_deOne1_R .btn {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pro_deOne1_R .btn a {
  height: clamp(2.5rem, 2.319rem + 0.81vw, 3.125rem);
  width: clamp(10.375rem, 9.976rem + 1.77vw, 11.75rem);
  margin-bottom: 5px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  background-color: transparent;
  color: var(--hue);
  border: 1px solid var(--hue);
  border-radius: 100px;
  margin-right: clamp(0.625rem, 0.375rem + 1.25vw, 1.875rem);
}
.pro_deOne1_R .btn a:hover {
  background-color: var(--hue);
  color: #fff !important;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.pro_deOne1_R .btn a:nth-child(1) {
  background-color: var(--hue);
  color: #fff;
}
.pro_deOne1_R .btn a:nth-child(3) {
  color: #999999;
  border-color: #999999;
}
.share_box_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.share_box_list a {
  width: clamp(1.875rem, 1.738rem + 0.69vw, 2.563rem);
  height: clamp(1.875rem, 1.738rem + 0.69vw, 2.563rem);
  border-radius: 3px;
  border: 1px solid transparent;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin-right: clamp(0.625rem, 0.5rem + 0.63vw, 1.25rem);
}

.pro_deOne1_R .share_box {
  padding-top: clamp(0.625rem, 0.262rem + 1.61vw, 1.875rem);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

.pro_deOne1_R .share_box .share_text {
  padding-right: clamp(0.625rem, 0.5rem + 0.63vw, 1.25rem);
}
/* pro_deBoxTwo */

.pro_deBoxTwo {
  position: relative;
  height: 130px;
}
.pro_deTwo1 {
  background-color: var(--hue);
}

.pro_deTwo1_list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.pro_deTwo1_list .item {
  width: calc(100% / 5);
  height: 130px;
  position: relative;
  cursor: pointer;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.pro_deTwo1_list .item .iconfont {
  border-radius: 100%;
  color: #fff;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.pro_deTwo1_list .item span {
  display: inline-block;
  color: #fff;
  padding-left: 8px;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.pro_deTwo1_list .item:hover,
.pro_deTwo1_list .item.hover {
  background-color: var(--hue2);
}

/* pro_del_three */
.pro_del_three_l {
  width: 50%;
  position: relative;
}
.pro_del_three_r {
  width: 43%;
}
.pro_del_three_l .video_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 2;
}
.pro_del_three_l .pic {
  border-radius: 10px;
  overflow: hidden;
}
.pro_del_three_l img {
  width: 100%;
  height: 100%;
}
/* pro_del_four */
.pro_del_four_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}

.pro_del_four_table .item table {
  width: 100%;
}
.pro_del_four_table .item table td {
  text-align: center;
  padding: 0.78vw;
  border: 1px solid rgba(0, 0, 0, 10%);
  font-size: 14px;
  font-family: var(--fwr);
  color: #333;
}
.pro_del_four_table .item table tr:first-child {
  background-color: var(--hue) !important;
}
.pro_del_four_table .item table tr:first-child td {
  color: #fff;
  font-size: 16px;
  font-family: var(--fwm);
}
.pro_del_four_table .item table tr:nth-child(odd) {
  background-color: #f5f5f5;
}
/* pro_del_six */
.pro_del_six_b .quan {
}
.pro_del_six_b .pic {
  width: 33%;
  margin: 0 2.6vw;
  position: relative;
}
.pro_del_six_b .pic .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.pro_del_six_b {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.pro_del_six_list {
  width: 32%;
  height: 100%;
}
.pro_del_six_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 6.77vw;
}
.pro_del_six_item:last-child {
  margin-bottom: 0;
}
.pro_del_six_item .left {
  width: calc(100% - clamp(3.375rem, 2.942rem + 1.92vw, 4.625rem) - 20px);
}
.pro_del_six_item .right {
  width: clamp(3.375rem, 2.942rem + 1.92vw, 4.625rem);
  height: clamp(3.375rem, 2.942rem + 1.92vw, 4.625rem);
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid var(--hue);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}
.pro_del_six_item .right img {
  width: 60%;
}
.pro_del_six_list:last-child .pro_del_six_item {
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}
.pro_del_six_item .right .hide {
  display: none;
}
.pro_del_six_item:hover .right {
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.pro_del_six_item:hover .left .title{
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.pro_del_six_item:hover .right .show {
  display: none;
}
.pro_del_six_item:hover .right .hide {
  display: block;
}

.index_three_dashed {
  width: 100%;
  border: 4px dashed rgba(0, 90, 156, 33%);
  border-radius: 100%;
  padding: clamp(1.25rem, 0.817rem + 1.92vw, 2.5rem);
  position: relative !important;
}
.index_three_dashed .quan {
  position: relative;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  animation: rotates 30s linear infinite;
}
.index_three_dashed .quan::before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: -16px;
  background-color: var(--hue);
}
@keyframes rotates {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.index_three_solid {
  border: 4px solid rgba(0, 90, 156, 50%);
  border-radius: 100%;
  padding: 20px;
}

.index_three_pic {
  position: relative;
}
.index_three_pic .bg {
  width: 100%;
  height: 100%;
  transform: translate(0%, -5%);
}
.index_three_pic img {
  width: 100%;
  height: 100%;
  transform: scale(1.25, 1.25);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
/* pro_del_four */
.pro_del_four_table table {
  width: 100%;
}
.pro_del_four_table table tr:first-child {
  background-color: #f6fbff;
}
.pro_del_four_table table tr:first-child td {
  font-size: clamp(1rem, 0.927rem + 0.32vw, 1.25rem);
  font-family: var(--fwm);
}
.pro_del_four_table table tr td {
  border: 1px solid rgba(0, 0, 0, 10%);
  text-align: center;
  padding: 1.04vw;
  line-height: 1.3;
  font-size: clamp(1rem, 0.964rem + 0.16vw, 1.125rem);
  font-family: var(--fwr);
  color: #333333;
}
/* pro_del_five */
.pro_del_five_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.pro_del_five_item {
  display: block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: calc((100% - 2.08vw) / 2);
}
.pro_del_five_item .pic {
  width: 100%;
  z-index: -1;
  overflow: hidden;
}
.pro_del_five_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.pro_del_five_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.pro_del_five_item .bottom {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 1.77vw 4.17vw;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  background-color: rgba(255, 255, 255, 94%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.pro_del_five_item:hover .bottom {
  background-color: rgba(0, 90, 156, 94%);
}
.pro_del_five_item:hover .bottom .text {
  color: #fff;
}
/* pro_del_seven */
.pro_del_seven_con {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-color: var(--hue);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding: 60px 5.21vw;
}
.pro_del_seven_r {
  width: 55%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-end;
  -ms-justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.pro_del_seven_l {
  width: 45%;
}
.pro_del_seven_l .title {
  line-height: 1.4;
}
.pro_del_seven_r .public_btn {
  color: var(--hue);
  background-color: #fff;
  height: clamp(2.5rem, 2.137rem + 1.61vw, 3.75rem);
  width: clamp(20rem, 18.548rem + 6.45vw, 25rem);
}
/* pro_del_eight */
.pro_del_eight_item {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
}
/* .pro_del_eight_item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 30%);
} */
.pro_del_eight_item .pic {
  width: 100%;
  overflow: hidden;
  /* filter: grayscale(100%); */
}
.pro_del_eight_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.pro_del_eight_item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: clamp(0.625rem, 0.28rem + 1.53vw, 1.813rem) 0;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(
    to top,
    rgba(0, 90, 156, 100%),
    rgba(0, 90, 156, 0%)
  );
  opacity: 0;
}
/* .pro_del_eight_swiper .swiper-slide-active .pro_del_eight_item::before {
  opacity: 0;
} */
.pro_del_eight_swiper .swiper-slide-active .pro_del_eight_item .title {
  opacity: 1;
}
/* .pro_del_eight_swiper .swiper-slide-active .pro_del_eight_item .pic {
  filter: grayscale(0);
} */
.pro_del_eight_b {
  position: relative;
  width: 100%;
}
.pro_del_eight_btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: 92%;
  max-width: 1600px;
  z-index: 2;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}
.pro_del_eight_btn .next {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.pro_del_eight_btn .iconfont {
  font-size: clamp(1.5rem, 1.427rem + 0.32vw, 1.75rem);
  width: clamp(2.625rem, 2.444rem + 0.81vw, 3.25rem);
  height: clamp(2.625rem, 2.444rem + 0.81vw, 3.25rem);
  background-color: #fff;
  color: var(--hue);
  border-radius: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
}
.pro_del_eight_btn .iconfont:hover {
  background-color: var(--hue);
  color: #fff;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/* pro_list_item */
.pro_del_nine_swiper {
  width: calc(100% - 10px);
  padding: 10px;
}
.pro_list_item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
}
.pro_list_item .pic {
  display: block;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.pro_list_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.pro_list_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.pro_list_item .bottom {
  background-color: #fff;
  text-align: center;
  padding: clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
}
.pro_list_item .title {
  display: block;
  margin-bottom: 0.78vw;
}
.pro_list_item .text {
  margin-bottom: 1.3vw;
}
.pro_list_item .btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}
.pro_list_item .public_btn {
  width: 160px;
  height: 40px;
}
.pro_list_item .public_btn:last-child {
  background-color: #fff;
  color: var(--hue);
  border: 1px solid var(--hue);
  margin-left: 2.6vw;
}
/* pro_del_form */
.pro_del_form {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 2;
}
.pro_del_form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 97%);
  z-index: -1;
}
.contact_form_b form {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.contact_form_b form .item {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;
  margin-bottom: 20px;
}
.contact_form_b form .item:nth-child(2n) {
  margin-right: 0;
}
.contact_form_b form .item input {
  width: 100%;
  padding-left: clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem);
  height: clamp(2.5rem, 2.284rem + 0.96vw, 3.125rem);
  background-color: #fff;
  font-size: 16px;
  color: #333;
  font-family: var(--fwr);
  border-color: rgba(0, 0, 0, 20%);
  border-radius: 4px;
}
.contact_form_b form .item.textarea textarea,
.contact_form_b form .item input::placeholder {
  color: #666;
  font-family: var(--fwr);
  font-size: 16px;
}
.contact_form_b form .item.textarea {
  width: 100%;
  margin-right: 0;
}
.contact_form_b form .item.textarea textarea {
  border-radius: 4px;
  width: 100%;
  resize: none;
  border-color: rgba(0, 0, 0, 20%);
  height: clamp(7.5rem, 5.685rem + 8.06vw, 13.75rem);
  padding: 13px 0 0 clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem);
  background-color: #fff;
}
.contact_form_b form .item.submit {
  width: 100%;
}
.contact_form_b form .item.submit .title {
  margin-bottom: 2.08vw;
}
.contact_form_b form .item.submit input {
  width: clamp(11.25rem, 10.343rem + 4.03vw, 14.375rem);
  height: clamp(2.5rem, 1.956rem + 2.42vw, 4.375rem);
  cursor: pointer;
  background-color: var(--hue);
  color: #fff;
  padding: 0;
  border-radius: 10px;
  font-size: 20px;
  font-family: var(--fwm);
  margin: auto;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}
/*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_del   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_list    ~~~~~~~~~~~*/
.pro_one_list:last-child {
  padding-bottom: 0;
}
.pro_one_list:first-child {
  padding-top: 0;
}
.pro_one_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
  position: relative;
  padding: 1.56vw 0;
}
.pro_one_list::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent 50%,
    transparent 50%,
    rgba(0, 0, 0, 10%) 100%,
    rgba(0, 0, 0, 10%) 100%
  );
  background-size: 20px 1px;
  background-repeat: repeat-x;
}
.pro_one_list:last-child:before {
  display: none;
}
.pro_one_list .list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  row-gap: 1rem;
}

.pro_one_list .title {
  padding: clamp(0.625rem, 0.498rem + 0.56vw, 1.063rem) 0 clamp(0.625rem, 0.498rem + 0.56vw, 1.063rem) 0;
}

.pro_one_item {
  white-space: nowrap;
  padding: clamp(0.625rem, 0.498rem + 0.56vw, 1.063rem)
    clamp(0.938rem, 0.484rem + 2.02vw, 2.5rem);
  border-radius: 100px;
  color: #333;
  font-size: 16px;
  font-family: var(--fwr);
  margin: 0 clamp(0.625rem, 0.444rem + 0.81vw, 1.25rem);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  background-color: #f9f9f9;
}
.pro_one_item:hover,
.pro_one_item.hover {
  color: #fff;
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.pro_list_dl {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.pro_list_dl dd {
  width: calc((100% - 5.21vw) / 3);
  margin-bottom: 2.6vw;
  margin-right: 2.6vw;
}
.pro_list_dl dd:nth-child(3n) {
  margin-right: 0;
}
.pro_list_dl .pro_list_item .pic {
  border: 1px solid rgba(0, 0, 0, 10%);
}
/* pro_two */
.pro_two_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.pro_two_item {
  min-width: calc((100% - 10.42vw) / 6);
  margin-right: 2.6vw;
  text-align: center;
  margin: auto;
  padding: 2.6vw 1.56vw;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 90, 156, 20%);
}
.pro_two_item:nth-child(5) {
  margin-right: 0;
}
.pro_two_item:hover .title .tt {
  color: var(--hue);
}
.pro_two_item .title {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-top: 1.04vw;
}
.pro_two_item .tt {
  margin-left: 0.52vw;
}
.pro_two_item img {
  margin:0 auto;
}
.pro_two_item .pic{
  /* filter: grayscale(100%) contrast(150%);  */
  filter: grayscale(100%) contrast(150%); 
}

.pro_two_item:hover .pic{
  filter: none;
}

/* .pro_two_item .pic .hide {
  display: none;
}
.pro_two_item:hover .pic .hide {
  display: block;
  margin: auto;
}
.pro_two_item:hover .pic .show {
  display: none;
} */

/* pro_three */
.pro_three_b {
  width: calc((100vw - 1px) / 2 + 100%);
}
.pro_three_swiper {
  padding: 30px 5px;
}
.cases_list_item {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 8%); */
}
.cases_list_item .pic {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.cases_list_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.cases_list_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.cases_list_item .bottom {
  padding: 1.56vw 1.56vw 30px 1.56vw;
  position: relative;
}
.cases_list_item .title {
  margin-bottom: 0.78vw;
}
.cases_list_item .btn {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 50%);
  -ms-transform: translate(0%, 50%);
  -webkit-transform: translate(0%, 50%);
}
.cases_list_item .public_btn {
  background-color: #fff;
  color: var(--hue);
  border: 1px solid var(--hue);
}
.cases_list_item:hover .public_btn {
  background-color: var(--hue);
  color: #fff;
}
/*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_cover    ~~~~~~~~~~~*/
.cover_one_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}
.cover_one_item {
  width: calc((100% - 2.08vw) / 2);
  display: block;
  position: relative;
  border-radius: 10px;
  margin-bottom: 2.08vw;
  overflow: hidden;
}
.cover_one_item .pic {
  width: 100%;
  overflow: hidden;
}
.cover_one_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.cover_one_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.cover_one_item .con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3.65vw;
  z-index: 2;
}
.cover_one_item .title {
  margin-bottom: 1.04vw;
}
.cover_one_item .text {
  margin-bottom: 1.56vw;
}
.cover_one_item .btn {
  display: inline-block;
  border-bottom: 1px solid #fff;
}
.cover_one_item .tt {
  margin-right: 5px;
  vertical-align: middle;
}
/* cover_two */
.cover_two_swiper {
  padding: 10px 0 60px 0;
}
.cover_two_item {
  display: block;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
  background-color: #fff;
  padding: 2.08vw 2.6vw 70px 2.6vw;
  text-align: center;
}
.cover_two_item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 11px);
  border-radius: 10px;
  z-index: -1;
  background-color: #d2d2d2;
}
.cover_two_item .text {
  margin-bottom: 2.08vw;
}
.cover_two_item .pic {
  max-width: 89px;
  margin: auto;
  overflow: hidden;
  position: absolute;
  bottom: -2%;
  left: 0;
  right: 0;
  width: 100%;
  transform: translate(0%, 50%);
  -ms-transform: translate(0%, 50%);
  -webkit-transform: translate(0%, 50%);
  border-radius: 100%;
}
.cover_two_item .pic img {
  width: 100%;
  height: 100%;
}
.cover_two_swiper .swiper-slide-active .cover_two_item::before {
  background-color: var(--hue);
}
/* cover_three */
.cover_three .cases_list_item .btn {
  position: relative;
  transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  margin-top: 1.04vw;
}
/*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~product_cover   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~case_list    ~~~~~~~~~~~*/
.case_list_item:first-child {
  padding-top: 0;
}
.case_list_item:nth-child(2n) {
  background-color: #f5f5f5;
}
.case_list_item:nth-child(2n) .case_list_con {
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}
.case_list_item:nth-child(2n) .case_list_r {
  padding-right: 0;
  padding-left: 7.29vw;
}
.case_list_item:nth-child(2n) .case_list_l::before {
  left: -20px;
}
.case_list_item {
  padding: 4.69vw 0;
}
.case_list_l {
  width: 37%;
  position: relative;
}
.case_list_r {
  width: 58%;
  padding-right: 7.29vw;
}
.case_list_l .pic {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.case_list_l .pic img {
  width: 100%;
  height: 100%;
}
.case_list_l::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
  height: 100%;
  background-color: #cfcfcf;
  border-radius: 10px;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.case_list_item:hover .title {
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.case_list_item:hover .case_list_l::before {
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.case_list_item .title {
  margin-bottom: 1.04vw;
}
.case_list_item .text {
  margin-bottom: 1.56vw;
}
.case_list_item .public_btn {
  background-color: #fff;
  border: 1px solid var(--hue);
  color: var(--hue);
}
.case_list_item:hover .public_btn {
  color: #fff;
  background-color: var(--hue);
}
.cases_list_box {
  margin-bottom: clamp(1.875rem, 1.331rem + 2.42vw, 3.75rem);
}
/*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~case_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ Case Details    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ Case Details    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ Case Details    ~~~~~~~~~~~*/
.case_one_con {
  width: 100%;
}
.case_one_h1 {
  margin-bottom: 20px;
}
.case_one_01 {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding-bottom: clamp(0.938rem, 0.75rem + 0.94vw, 1.875rem);
}

.case_one_01 .left {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.case_one_01 .left .item {
  margin-right: 3.13vw;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.case_one_01 .right {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}

.case_one_share {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.case_one_01 .iconfont {
  margin-right: 5px;
}

.case_one_share a {
  display: block;
  width: 26px;
  height: 26px;
  border: 1px solid;
  border-radius: 3px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin-left: 12px;
}

.case_one_02 img {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
}

.case_one_03 .title {
  margin-bottom: clamp(0.938rem, 0.75rem + 0.94vw, 1.875rem);
}

.case_one_04 .img_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: 100%;
}

.case_one_04 .img_list .img_item {
  width: calc((100% - 9.11vw) / 6);
  margin-right: 1.82vw;
  margin-bottom: 2.08vw;
  cursor: pointer;
}

.case_one_04 .img_list .img_item:nth-child(6n) {
  margin-right: 0;
}

.case_one_04 .img_list .img_item img {
  width: 100%;
}

.case_one_04 .btn_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: 100%;
}

.case_one_04 .btn_list .item {
  display: block;
  border: 1px solid #666;
  color: #666;
  font-size: 16px;
  font-family: var(--fwr);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  border-radius: 40px;
  margin-bottom: 10px;
  margin-right: clamp(0.625rem, 0.5rem + 0.63vw, 1.25rem);
  padding: clamp(0.313rem, 0.25rem + 0.31vw, 0.625rem)
    clamp(0.938rem, 0.775rem + 0.81vw, 1.75rem);
}

.case_one_04 .btn_list .item:hover {
  background-color: var(--hue);
  border-color: var(--hue);
  color: #fff;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.case_one_t {
  padding-bottom: clamp(0.938rem, 0.625rem + 1.56vw, 2.5rem);
}

.case_one_b {
  border-top: 1px solid #ededed;
  padding-top: clamp(0.938rem, 0.625rem + 1.56vw, 2.5rem);
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.case_one_btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-right: 9.9vw;
  margin-bottom: 10px;
}
.case_one_btn:last-child {
  margin-right: 0;
}
.case_one_btn .iconfont {
  width: 12%;
}
.case_one_btn:hover div {
  color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/* case_two_b */
.case_two_b {
  width: calc((100vw - 1px) / 2 + 50%);
}
.case_two_swiper{
  padding: 10px;
}
.case_item {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
  border-radius: 10px;
  display: block;
  position: relative;
  width: 100%;
}
.case_item .pic {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.case_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.case_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.case_item .bottom {
  padding: 1.56vw;
}
.case_item .title {
  margin-bottom: 1.04vw;
}
.case_item .trim {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.case_item .trim .tt {
  margin-left: 0.52vw;
}
.case_two_t .text {
  margin: 0;
}

/*~~~~~~~~~~~~ Case Details   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ Case Details   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~ Case Details   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~soution    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~soution    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~soution    ~~~~~~~~~~~*/
.soution_one_con {
  width: 100%;
  position: relative;
}
.soution_one_l {
  width: 21.2%;
}
.soution_one_box {
  position: sticky !important;
  top: 120px !important;
  left: 0 !important;
  z-index: 2 !important;
}
.soution_one_r {
  margin-left: 3.13vw;
  width: calc((78.8% - 3.13vw));
}

.soution_one_t {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
  border-radius: 10px;
  margin-bottom: 2.08vw;
  overflow: hidden;
}
.soution_one_t .title {
  text-align: center;
  background-color: var(--hue);
  padding: clamp(0.688rem, 0.488rem + 0.89vw, 1.375rem);
}
.soution_one_t_list {
  padding: 10px 0 10px 1.56vw;
}
.soution_one_t_list .item {
  cursor: pointer;
  width: 100% !important;
  line-height: 1.2;
  display: block;
  padding: clamp(1rem, 0.927rem + 0.32vw, 1.25rem) 0
    clamp(0.469rem, 0.333rem + 0.6vw, 0.938rem) 0;
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.soution_one_t_list .item:last-child {
  border-bottom: none;
}
.soution_one_t_list .item:hover {
  color: var(--hue);
  font-family: var(--fwm);
  font-size: clamp(1rem, 0.927rem + 0.32vw, 1.25rem);
}
.soution_one_b {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
}
.soution_one_b .pic {
  width: 100%;
}
.soution_one_b .pic img {
  width: 100%;
  height: 100%;
}
.soution_one_b .soution_one_t_list .item:hover {
  font-family: var(--fwr);
  color: #333;
  font-size: 16px;
}
.soution_one_b .left {
  font-size: 20px;
  margin-top: -3px;
  margin-right: 3px;
}
.soution_one_b .right {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: calc(100% - 25px);
  padding-right: 30px;
}
.soution_one_b .right a {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
}
.soution_one_b .item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
}
.soution_one_b .tt {
  font-family: var(--fwm);
}
.soution_one_h1 {
  margin-bottom: 2.08vw;
}
.soution_one_01_title {
  margin-bottom: 30px;
}
.soution_one_01_text {
  margin-bottom: 2.08vw;
}
.soution_one_01 .pic {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 2.08vw;
}
.soution_one_01 .pic .item {
  width: calc((100% - 2.08vw) / 2);
  border-radius: 10px;
  overflow: hidden;
}
.soution_one_01 .pic .item img {
  width: 100%;
  height: 100%;
}
.soution_one_01 .list .item {
  margin-bottom: 1.04vw;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
}
.soution_one_01 .list .item:last-child {
  margin-bottom: 0;
}
.soution_one_01 .list .item .number {
  margin-right: 5px;
}
.soution_one_01 .list .item .tt {
  margin-bottom: 0.78vw;
}
.soution_one_02 .pic {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}
.soution_one_03_item .pic {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.soution_one_03_item .pic img {
  width: 100%;
  height: 100%;
}
.soution_one_03_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.soution_one_03_item {
  display: block;
  position: relative;
  width: 100%;
}
.soution_one_03_item .title {
  padding: 1.04vw 0;
}
.soution_one_04_swiper {
  padding: 10px;
  width: calc((100vw - 1px) / 2 + 50%);
}
.soution_one_04_swiper .pro_list_item .pic {
  border: 1px solid rgba(0, 0, 0, 10%);
}
/*~~~~~~~~~~~~soution   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~soution   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~soution   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~solution_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~solution_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~solution_list    ~~~~~~~~~~~*/
/* .solution_list_table {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  overflow-x: auto;
  width: 100%;
}
.solution_list_table .item {
  display: block;
  padding: clamp(0.625rem, 0.498rem + 0.56vw, 1.063rem)
    clamp(0.625rem, 0.081rem + 2.42vw, 2.5rem);
  margin: 0 0.52vw clamp(0.469rem, 0.333rem + 0.6vw, 0.938rem) 0;
  color: #333;
  border-radius: 100px;
  overflow: hidden;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.solution_list_table .item.hover,
.solution_list_table .item:hover {
  background-color: var(--hue);
  color: #fff;
  font-size: 16px;
  font-family: var(--fwr);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
} */



.solution_list_dl {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-top: 30px;
}
.solution_list_dl dd {
  width: calc((100% - 5.21vw) / 3);
  margin: 0 2.6vw 2.6vw 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 8%);
}
.solution_list_dl dd:nth-child(3n) {
  margin-right: 0;
}
.solution_list_dl .cases_list_item .btn {
  position: relative;
  margin-top: 1.04vw;
  transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
}
/*~~~~~~~~~~~~solution_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~solution_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~solution_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~service    ~~~~~~~~~~~*/
.service_one_l {
  width: 43%;
}
.service_one_r {
  width: 50%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.service_one_r .item {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.service_one_r .item img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.service_one_r .item:hover img {
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.service_one_b {
  position: relative;
  width: 100%;
}
.service_one_b .top {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}
.service_one_b .top::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 10%);
}
.service_one_b_swiper {
  width: calc(100% - 15.63vw);
  padding-bottom: 2px;
}
.service_one_b_item {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin: auto;
  position: relative;
  height: 40px;
}
.service_one_b_item::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 2px;
  z-index: 2 !important;
  background-color: var(--hue);
  opacity: 0;
}
.service_one_b_item .number {
  color: var(--hue);
  font-family: var(--fwm);
  font-size: clamp(1rem, 0.927rem + 0.32vw, 1.25rem);
  margin-right: 0.52vw;
}

.service_one_b_item .title {
  color: #333;
  font-size: 16px;
  white-space: nowrap;
  font-family: var(--fwm);
}
.service_one_b_swiper .swiper-slide-thumb-active .service_one_b_item::before {
  opacity: 1;
}
.service_one_b_swiper .swiper-slide-thumb-active .service_one_b_item .title {
  color: var(--hue);
}
.service_one_l .number {
  color: #c8c8c8;
  margin-bottom: 1.04vw;
}
.service_one_l .title {
  margin-bottom: 1.04vw;
}
.service_one_l .text {
  margin-bottom: 1.56vw;
}
.service_one_btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
}
.service_one_btn .iconfont {
  width: clamp(2.5rem, 2.3rem + 0.89vw, 3.188rem);
  height: clamp(2.5rem, 2.3rem + 0.89vw, 3.188rem);
  border-radius: 100%;
  background-color: #f5f5f5;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin-right: 30px;
  cursor: pointer;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.service_one_btn .next {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.service_one_btn .iconfont:hover {
  background-color: var(--hue);
  color: #fff;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/* .service_two */
.service_two_b {
  width: calc((100% - 10px) / 2 + 100%);
}
.service_Two_swiper {
  padding: 30px;
}
.service_Two_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 10%);
}
.service_Two_item .pic {
  width: 49%;
  border-radius: 10px;
  overflow: hidden;
  transform: translate(-30px, -30px);
  -ms-transform: translate(-30px, -30px);
  -webkit-transform: translate(-30px, -30px);
}

.service_Two_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.service_Two_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.service_Two_item .con {
  width: 50%;
  position: relative;
  text-align: center;
  padding-right: 2.08vw;
}
.service_Two_item .title {
  margin-bottom: 0.78vw;
}
.service_Two_item .text {
  margin-bottom: 1.3vw;
}
.service_Two_item .sm {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}
.service_Two_item .sm a {
  font-size: 16px;
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  margin-right: 1.56vw;
}
.service_Two_item .sm a:last-child {
  margin-right: 0;
}
/* service_three */
.service_three_item {
  display: block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.service_three_item .pic img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.service_three_item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: clamp(0.625rem, 0.462rem + 0.73vw, 1.188rem)
    clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
  background-color: rgba(0, 90, 156, 90%);
  border-radius: 0 10px 0 0;
  opacity: 0;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.service_three_item:hover .pic img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.service_three_swiper_pagination {
  margin-top: 3.13vw;
}
.service_three_swiper_pagination .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 1px solid var(--hue);
  opacity: 1;
}
.service_three_swiper_pagination .swiper-pagination-bullet-active {
  background-color: var(--hue);
}
.service_three_swiper .swiper-slide-next .service_three_item .title,
.service_three_swiper .swiper-slide-active .service_three_item .title {
  opacity: 1;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/* service_four */
.service_four_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.service_four_item {
  display: block;
  width: calc((100% - 20.84vw) / 5);
  margin-right: 5.21vw;
  margin-bottom: 2.08vw;
}
.service_four_item:nth-child(5n) {
  margin-right: 0;
}
.service_four_item img {
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.service_four_item:hover img {
  filter: grayscale(0%);
  transform: translate(5%, -5%);
  -ms-transform: translate(5%, -5%);
  -webkit-transform: translate(5%, -5%);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
/*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~service   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~news_list    ~~~~~~~~~~~*/
.news_two {
  background-color: #fff;
}
.news_two_t_l {
  /* width: 58%; */
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  padding-bottom: 10px;
}

.news_two_t_l .item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  color: #333;
  font-family: var(--fwr);
  margin-right: 3.65vw;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.news_two_t_l .item .iconfont {
  margin-right: 10px;
}

.news_two_t_l .item.hover,
.news_two_t_l .item:hover {
  font-family: var(--fwm);
  color: var(--hue);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.news_two_t_r {
  /* width: 42%; */
}
.news_two_t_r form {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  background-color: #f5f5f5;
  padding: 0 20px;
  border-radius: 100px;
  overflow: hidden;
  height: clamp(3.125rem, 2.692rem + 1.92vw, 4.375rem);
}

.news_two_t_r input {
  width: calc(100% - 60px);
  height: 100%;
  border-color: transparent;
}
.news_two_t_r input::placeholder {
  font-family: var(--fwr);
  font-size: 16px;
}

.news_two_t_r .iconfont {
  width: 60px;
  height: 60px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
}

.news_list dd {
  margin-bottom: 1.56vw;
}

.news_list_item {
  display: block;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.news_list_item::after {
  opacity: 0;
  content: "";
  display: block;
  position: absolute;
  top: -1%;
  left: -1%;
  width: 102%;
  height: 102%;
  filter: blur(10px);
  background-color: rgba(0, 0, 0, 8%);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.news_list_item:hover {
  box-shadow: none;
  background-color: transparent;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.news_list_item:hover::after {
  opacity: 1;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.news_list_item_l {
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.news_list_item_l img {
  width: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.news_list_item:hover .news_list_item_l img {
  transform: scale(1.13, 1.13);
  -ms-transform: scale(1.13, 1.13);
  -webkit-transform: scale(1.13, 1.13);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.news_list_item_r {
  width: calc(100% - 400px - 3.44vw);
  margin-left: 3.44vw;
}

.news_list_item_r .title {
  padding-bottom: 1.04vw;
}

.news_list_item_r .text {
  padding-bottom: 2.34vw;
}

.news_list_item_r .right_trim {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  padding-bottom: 15px;
}

.news_list_item_r .right_trim .item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  padding-right: 2.08vw;
}

.news_list_item_r .right_trim .iconfont {
  margin-right: 10px;
}

.news_list_item_r .btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  font-size: 14px;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-left: 5px;
}

.news_list_item_r .btn .iconfont {
  margin-left: 0.52vw;
}
.news_list_dl {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 15px;
}
.news_list_dl dd {
  margin-bottom: 60px;
}
.news_list2_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.news_list2_item .left {
  width: 34%;
  position: relative;
  z-index: 2;
}
.news_list2_item .left img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.news_list2_item .left .pic {
  overflow: hidden;
  border-radius: 10px;
}

.news_list2_item .right {
  width: 62%;
  border-radius: 0 10px 10px 0;
}
.news_list2_item .right .trim {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-bottom: 13px;
}
.news_list2_item .right .trim .tt {
  margin-left: 10px;
}
.news_list2_item .title {
  margin-bottom: clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem);
}
.news_list2_item .text {
  margin-bottom: clamp(0.938rem, 0.397rem + 2.4vw, 2.5rem);
}
.news_list2_item .public_btn {
  height: 40px;
  border: 1px solid var(--hue);
  background-color: transparent;
}
.news_list2_item .public_btn {
  color: var(--hue);
}
.news_list2_item:hover .public_btn {
  background-color: var(--hue);
}
.news_list2_item:hover .public_btn .tt {
  color: #fff;
}
.news_list2_item:hover .title {
  color: var(--hue);
}
.news_list2_item:hover .left::before {
  background-color: var(--hue);
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.news_list2_item:hover .left img {
  transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transition: all 0.6s;
  -ms-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
/*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~news_list   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~contact_us    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~contact_us    ~~~~~~~~~~~*/
/*~~~~~~~~~~~~contact_us    ~~~~~~~~~~~*/
.contact_one_r {
  width: 48%;
  position: relative;
}
.contact_one_l {
  width: 48%;
  position: relative;
}
.contact_one_l .title {
  padding-bottom: 10px;
}
.contact_form_list {
  padding-top: clamp(0.938rem, 0.665rem + 1.21vw, 1.875rem);
  border-top: 1px solid rgba(255, 255, 255, 10%);
}
.contact_form_list .item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  margin-bottom: clamp(0.625rem, 0.409rem + 0.96vw, 1.25rem);
}
.contact_form_list .item .iconfont {
  font-size: 28px;
  width: 55px;
  height: 55px;
  margin-top: -5px;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -ms-align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  background-color: #f5f5f5;
  color: #666;
  border-radius: 100%;
}
.contact_form_list .item .con {
  width: calc(100% - 55px);
}
.contact_form_list .item .tt {
  color: #333;
}
.contact_form_list .item a {
  color: #333;
  font-family: var(--fwr);
}
.contact_form_list .item:hover .iconfont{
  background-color: var(--hue);
  color: #fff;
  transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.contact_right {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgb(0, 0, 0, 8%);
  position: relative;
  border-top: 20px solid var(--hue);
}

.contact_right_title {
  padding: clamp(1.25rem, 0.887rem + 1.61vw, 2.5rem);
}

.contact_right_form {
  padding: 0 clamp(1.25rem, 0.887rem + 1.61vw, 2.5rem);
}

.contact_form_box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.contact_form_box > .item {
  width: calc((100% - 2.08vw) / 2);
  margin-bottom: clamp(0.625rem, 0.353rem + 1.21vw, 1.563rem);
  color: #666;
  font-family: var(--fwr);
}

.contact_form_box > .item input {
  margin-top: 10px;
  border-radius: 5px;
  width: 100% !important;
  height: clamp(2.5rem, 2.137rem + 1.61vw, 3.75rem);
  padding: 0px 10px 0 10px;
  background-color: #f5f5f5;
  border: none;
}

.contact_form_input_title {
  color: #6a6a6a;
  padding-bottom: 5px;
}

.contact_form_textarea {
  width: 100% !important;
}

.contact_form_textareas {
  font-family: var(--fwr);
  border-color: transparent;
  width: 100%;
  height: clamp(5.625rem, 5.008rem + 2.74vw, 7.75rem);
  padding: 15px 10px 0px;
  border-radius: 3px;
  font-size: 16px;
  border: none;
  background-color: #f5f5f5;
  resize: none;
}

.contact_form_btn {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}

.contact_form_btn input, .contact_form_btn button {
  cursor: pointer;
  background-color: var(--hue);
  font-family: var(--fwm);
  font-size: 20px;
  color: #fff;
  border: none;
  border-radius: 100px;
  width: 100% !important;
  height: clamp(2.5rem, 2.284rem + 0.96vw, 3.125rem);
  text-align: center;
  overflow: hidden;
}
/* contact_two */
.contact_two img{
  width: 100%;
  height: 100%;
}
/*~~~~~~~~~~~~contact_us   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~contact_us   end ~~~~~~~~~~~*/
/*~~~~~~~~~~~~contact_us   end ~~~~~~~~~~~*/




/*  defined by chy  */

.share__list{
  display: flex;
  /* gap:5px; */
}

.share__list a:hover {
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.word-break{
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}


/***********  add by chy *************/

/* 分页 chy */
.chy-page{
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap:1em;
}

.chy-page li{
  /* min-width:36px;
  padding:8px 12px;
  text-align: center;
  background-color: #eee;
  border-radius: 8px; */
  background-color: #f5f5f5;
  color: #666;
  font-size: clamp(0.875rem, 0.788rem + 0.38vw, 1.125rem);
  width: clamp(1.688rem, 1.506rem + 0.81vw, 2.313rem);
  height: clamp(1.688rem, 1.506rem + 0.81vw, 2.313rem);
  line-height: clamp(1.688rem, 1.506rem + 0.81vw, 2.313rem);
  /* margin: 0px clamp(0.313rem, 0.204rem + 0.48vw, 0.625rem); */
  border-radius: 5px;
  text-align: center;
}

.chy-page .active{
  color:#fff;
  background-color: rgb(37, 99, 235);
}

/* 内容页 chy */
article img{
  display: block;
  margin:1.5rem auto;
}

article p{
  margin:1rem 0;
}


/* 用于弥补滚动时顶端的空白 */
.scroll-margin-top{
  scroll-margin-top: 120px;
}


/* table 折叠 chy */
table.collapse-chy tr:nth-child(n+7){
  display: none;
} 

.btn-tab-colapse-chy{
  display: block;
  margin:5px auto;
  background-color: #F0F0F0;
  border-width: 0;
  cursor: pointer;
}

.btn-tab-colapse-chy:hover{
  background-color:#ccc;
}

.btn-tab-colapse-chy[data-colapse='1']:after{
  content: '▲ flow up';
}

.btn-tab-colapse-chy::after{
  content: '▼ more';
  padding:0 1rem;
  font-size: .6rem;
}

/* 通用页 客服 z-500 */
#LRfloater0{
  z-index: 500 !important;
}
