/**
 *               ii.                                         ;9ABH,          
 *              SA391,                                    .r9GG35&G          
 *              &#ii13Gh;                               i3X31i;:,rB1         
 *              iMs,:,i5895,                         .5G91:,:;:s1:8A         
 *               33::::,,;5G5,                     ,58Si,,:::,sHX;iH1        
 *                Sr.,:;rs13BBX35hh11511h5Shhh5S3GAXS:.,,::,,1AG3i,GG        
 *                .G51S511sr;;iiiishS8G89Shsrrsh59S;.,,,,,..5A85Si,h8        
 *               :SB9s:,............................,,,.,,,SASh53h,1G.       
 *            .r18S;..,,,,,,,,,,,,,,,,,,,,,,,,,,,,,....,,.1H315199,rX,       
 *          ;S89s,..,,,,,,,,,,,,,,,,,,,,,,,....,,.......,,,;r1ShS8,;Xi       
 *        i55s:.........,,,,,,,,,,,,,,,,.,,,......,.....,,....r9&5.:X1       
 *       59;.....,.     .,,,,,,,,,,,...        .............,..:1;.:&s       
 *      s8,..;53S5S3s.   .,,,,,,,.,..      i15S5h1:.........,,,..,,:99       
 *      93.:39s:rSGB@A;  ..,,,,.....    .SG3hhh9G&BGi..,,,,,,,,,,,,.,83      
 *      G5.G8  9#@@@@@X. .,,,,,,.....  iA9,.S&B###@@Mr...,,,,,,,,..,.;Xh     
 *      Gs.X8 S@@@@@@@B:..,,,,,,,,,,. rA1 ,A@@@@@@@@@H:........,,,,,,.iX:    
 *     ;9. ,8A#@@@@@@#5,.,,,,,,,,,... 9A. 8@@@@@@@@@@M;    ....,,,,,,,,S8    
 *     X3    iS8XAHH8s.,,,,,,,,,,...,..58hH@@@@@@@@@Hs       ...,,,,,,,:Gs   
 *    r8,        ,,,...,,,,,,,,,,.....  ,h8XABMMHX3r.          .,,,,,,,.rX:  
 *   :9, .    .:,..,:;;;::,.,,,,,..          .,,.               ..,,,,,,.59  
 *  .Si      ,:.i8HBMMMMMB&5,....                    .            .,,,,,.sMr 
 *  SS       :: h@@@@@@@@@@#; .                     ...  .         ..,,,,iM5 
 *  91  .    ;:.,1&@@@@@@MXs.                            .          .,,:,:&S 
 *  hS ....  .:;,,,i3MMS1;..,..... .  .     ...                     ..,:,.99 
 *  ,8; ..... .,:,..,8Ms:;,,,...                                     .,::.83 
 *   s&: ....  .sS553B@@HX3s;,.    .,;13h.                            .:::&1 
 *    SXr  .  ...;s3G99XA&X88Shss11155hi.                             ,;:h&, 
 *     iH8:  . ..   ,;iiii;,::,,,,,.                                 .;irHA  
 *      ,8X5;   .     .......                                       ,;iihS8Gi
 *         1831,                                                 .,;irrrrrs&@
 *           ;5A8r.                                            .:;iiiiirrss1H
 *             :X@H3s.......                                .,:;iii;iiiiirsrh
 *              r#h:;,...,,.. .,,:;;;;;:::,...              .:;;;;;;iiiirrss1
 *             ,M8 ..,....,.....,,::::::,,...         .     .,;;;iiiiiirss11h
 *             8B;.,,,,,,,.,.....          .           ..   .:;;;;iirrsss111h
 *            i@5,:::,,,,,,,,.... .                   . .:::;;;;;irrrss111111
 *            9Bi,:,,,,......                        ..r91;;;;;iirrsss1ss1111
 */
/*
 * author 1452726147@qq.com
 */

@charset "utf-8";

/*reset*/

#baguetteBox-overlay {
	display: none;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #F0F0F0;
}

::-webkit-scrollbar-thumb {
	background-color: #CDCDCD;
	border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #D9D9D9
}

::-webkit-scrollbar-thumb:active {
	background-color: #554F5E
}
* {
	padding: 0;
	margin: 0;
}

*,
:before,
:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
em,
img,
strong,
sub,
sup,
dd,
dl,
dt,
li,
ol,
ul,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

li {
	list-style-type: none;
}

font {
	font-family: Arial, Verdana;
}

input,
button,
textarea,
select {
	margin: 0;
	padding: 0;
	border-width: 1px;
	font-size: 14px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	border-radius: 0;
	-webkit-appearance: none;
}

input,
button {
	-webkit-appearance: none;
	outline: none;
	background: none;
}

a img {
	border: 0 none;
	max-width: 100%;
}

iframe {
	border: 0;
}

img {
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}

a {
	text-decoration: none;
	transition: all .5s;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
}

a:hover {
	text-decoration: none;
	transition: all .5s;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
}

/* html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

body{
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
} */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

label,
button {
	cursor: pointer;
}


i {
	font-style: normal;
}
/* 版心 */
.w_1600 {
	width: 92%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
}

.w_1400 {
	width: 92%;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
}

/* 盒子 */
.wrap_bw {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	justify-content: space-between;
	-ms-justify-content: space-between;
	-webkit-justify-content: space-between;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}

.wrap_baw {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	justify-content: space-between;
	-ms-justify-content: space-between;
	-webkit-justify-content: space-between;
	align-items: center;
	-ms-align-items: center;
	-webkit-align-items: center;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}

.wrap_aw {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	align-items: center;
	-ms-align-items: center;
	-webkit-align-items: center;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
.textNowrap{
	white-space: nowrap;
}
/* 文字溢出 */
.textOverflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.textOverClm {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}


.clm2 {
	-webkit-line-clamp: 2;
}

.clm3 {
	-webkit-line-clamp: 3;
}

.clm4 {
	-webkit-line-clamp: 4;
}

.clm5 {
	-webkit-line-clamp: 5;
}

.clm6 {
	-webkit-line-clamp: 6;
}

.clm7 {
	-webkit-line-clamp: 7;
}

.clm8 {
	-webkit-line-clamp: 8;
}

.clm9 {
	-webkit-line-clamp: 9;
}
/* 字体颜色 */
.colorf {
	color: #fff;
}

.color0 {
	color: #000;
}

.color1 {
	color: #111;
}

.color2 {
	color: #222;
}

.color3 {
	color: #333;
}
.color4{
	color: #444;
}
.color6 {
	color: #666;
}

.color8 {
	color: #888;
}

.color9 {
	color: #999;
}
.upp {
	text-transform: uppercase;
}
.borderN {
	border: none;
}

.text_capitalize {
	text-transform: capitalize;
}


/* btn按钮样式 */
.hover1_style {
	position: relative;
	overflow: hidden;
	-ms-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.hover1_style:hover {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18) !important;
}

.hover1_style::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	-ms-background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8) 50%, transparent);
	-moz-background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8) 50%, transparent);
	-webkit-background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8) 50%, transparent);
	background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8) 50%, transparent);
	-ms-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.hover1_style:hover::after {
	left: 100%;
}

/* pub_scroll_btn1  swiper*/
.pub_scroll_btn1 {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	justify-content: center;
	-ms-justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
	width: 59px;
	height: 59px;
	background: var(--hue);
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}

/* 分页按钮 */
.public_page_btn1 {
	display: inline-block;
	vertical-align: middle;
	font-size: 16px;
	color: #333333;
	line-height: 50px;
	padding: 0 25px;
	border-radius: 6px;
	border: 1px solid #898989;
	cursor: pointer;
	-ms-transition: all 0.6s;
	-webkit-transition: all 0.6s;
	transition: all 0.6s;
}

.public_page_btn1:hover {
	color: #ffffff;
	border-color: var(--hue);
	background: var(--hue);
}

.public_page_btn2 {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	justify-content: center;
	-ms-justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
	vertical-align: middle;
	font-size: 16px;
	color: #ffffff;
	line-height: 50px;
	padding: 0 25px;
	border-radius: 25px;
	background-color: skyblue;
	cursor: pointer;
	-ms-transition: all 0.6s;
	-webkit-transition: all 0.6s;
	transition: all 0.6s;
}

.public_page_btn2 .icon {
	margin-right: 6px;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

.tc {
	text-align: center;
}

.tl {
	text-align: left;
}

.tr {
	text-align: right;
}

.textHover:hover {
	text-decoration: underline;
}


/* 按钮从左到右过渡 */
.btnHover2 {
	position: relative;
	overflow: hidden;
}

.btnHover2:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	opacity: 1;
	-webkit-transform: translate(-105%, 0);
	transform: translate(-105%, 0);
	border-right-width: 1px;
	border-right-style: solid;
	border-right-color: #fff;
	background-color: rgba(255, 255, 255, .25);
}

.btnHover2:hover:before {
	opacity: 0;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.imgZoom img,
.imgY180 img {
	-moz-transition: all .8s ease 0s;
	-ms-transition: all .8s ease 0s;
	-o-transition: all .8s ease 0s;
	-webkit-transition: all .8s ease 0s;
	transition: all .8s ease 0s;
}

.imgZoom {
	overflow: hidden;
	-webkit-transform: rotate(0);
	transform: rotate(0);
}

.imgZoom:hover img {
	-moz-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	-o-transform: scale(1.1, 1.1);
	-webkit-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

.imgY180:hover img {
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg)
}
.pro_page_table {
	overflow-x: auto;
	white-space: nowrap;
}
.pro_page_table::-webkit-scrollbar{
	height: 3px;
}

/* ~~~~~~~~~~~~border_line_video~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~border_line_video~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~border_line_video~~~~~~~~~~~~~~~ */

.cir_line_box {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 128px;
	height: 128px;
	transform: translate(-50%, -50%);
}
.cir_line_box .line1,
.cir_line_box .line2,
.cir_line_box .line3 {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0;
	border: 1px solid #fff;
	transform: translate(-50%, -50%);
	transform-origin: center center;
}

.cir_line_box .pic {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	justify-content: center;
	-ms-justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-ms-align-items: center;
	-webkit-align-items: center;
	height: 100%;
}

.cir_line_box .line1 {
	animation: aaaa3 3s infinite linear;
	animation-delay: -1s;
}

.cir_line_box .line2 {
	animation: aaaa3 3s infinite linear;
	animation-delay: 0s;
}

.cir_line_box .line3 {
	animation: aaaa3 3s infinite linear;
	animation-delay: 1s;
}

@keyframes aaaa3 {
	0% {
		width: 90%;
		height: 90%;
		opacity: 0;
	}

	50% {
		width: 120%;
		height: 120%;
		opacity: 0.45;
	}

	100% {
		width: 150%;
		height: 150%;
		opacity: 0;
	}
}
@keyframes rotates {
	0%{
	  transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	}
	100%{
	  transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	}
  }
/* ~~~~~~~~~~~~border_line_video end~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~border_line_video end~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~~~border_line_video end~~~~~~~~~~~~~~~ */
/* 背景 */
.bgf5 {
	background-color: #f5f5f5 !important;
}


.bgf {
	background-color: #fff;
}
.click_video{
	cursor: pointer;
}